import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  BATCH_ENUMS,
  BatchService,
} from 'src/app/common/services/batch/batch.service';
import { BatchDetailModel } from '../batch-overview/batch-overview.model';
import { BatchPlan } from 'src/app/common/services/batch/batch.modal';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { BATCH_PLUS_EVENT } from 'src/app/core/analytics-events';
import { PaymentService } from 'src/app/common/services/payment/payment.service';
import { Base64 } from 'src/app/common/utils/encode-utilities';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { CohortService } from 'src/app/common/services/cohort/cohort.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { BANNER_PEOPLE_IMG, ROCKET_IMG } from 'src/app/core/assets.location';
@Component({
  selector: 'app-batch-upgrade-pop-up',
  templateUrl: './batch-upgrade-pop-up.component.html',
  styleUrls: ['./batch-upgrade-pop-up.component.scss'],
})
export class BatchUpgradePopUpComponent implements OnInit {
  upgradeData: BatchPlan;
  batchData: BatchDetailModel;
  routeToNewFbt = false;
  userType: string = '';
  userSegmentGA: string;
  splitSection = 0;
  rocketImg = ROCKET_IMG;
  peopleImg = BANNER_PEOPLE_IMG;
  isClicked = false;

  constructor(
    private _router: Router,
    private _dialog: MatDialogRef<BatchUpgradePopUpComponent>,
    private _batchService: BatchService,
    private _firebaseAnalytics: FirebaseAnalyticsService,
    private cohortService: CohortService,
    private globalService: GlobalService,

    @Inject(MAT_DIALOG_DATA)
    public data: {
      upgradeData: BatchPlan;
      batchData: BatchDetailModel;
    },
    private paymentService: PaymentService,
    private storageService: StorageService
  ) {
    this.upgradeData = new BatchPlan(data?.upgradeData ?? {});
    this.batchData = new BatchDetailModel(data?.batchData ?? {});

    this.userType =
      this.globalService.getIsPathShalaFromStorage() === 'false'
        ? 'online'
        : 'offline';
    this.userSegmentGA =
      this.globalService.getUserSegmentFromStorage()?.toLowerCase() || '';
    if (this.upgradeData?.upgradeBanner?.itemImages?.length > 0) {
      this.switcherUi(this.upgradeData.upgradeBanner.itemImages.length);
    }
  }

  switcherUi(length: number) {
    switch (length) {
      case 1:
      case 2:
      case 3:
      case 4:
        this.splitSection = 1;
        break;
      case 5:
        this.splitSection = 2;
        break;
      case 6:
        this.splitSection = 3;
        break;
      default:
        this.upgradeData.upgradeBanner.itemImages =
          this.upgradeData.upgradeBanner.itemImages.splice(0, 6);
        this.splitSection = 3;
    }
  }

  async ngOnInit() {
    this.routeToNewFbt = this.paymentService._shouldRunNewFBT$.getValue();
    this.logEvent();
  }

  expoleBatchPlusTable() {
    this._batchService.openBatchPlanComparisonTable(
      this.batchData,
      BATCH_ENUMS.UPGRADE_BANNER,
      BATCH_ENUMS.UPGRADE_BANNER,
      this.upgradeData._id,
      () => {
        this.close();
      }
    );
    this.logGAEvent('explore_infinity_plan');
  }
  goToOrderSummary() {
    if (this.isClicked) return;
    this.isClicked = true;

    setTimeout(() => {
      this.isClicked = false;
    }, 1500);

    this.storageService.setLastVisitedUrl(this._router.url);
    if (this.routeToNewFbt) {
      const data = {
        batchSlug: this.batchData?._id,
        planId: this.upgradeData._id,
        cameFrom: BATCH_ENUMS.UPGRADE_BANNER,
      };

      this.storageService.setRouteToNewFbtToSessionStorage();
      const info = Base64.encode(JSON.stringify(data));
      this._router.navigate(['/payments'], {
        queryParams: {
          redirect_url: '/',
          info,
        },
      });
    } else {
      this._router.navigate(
        [`batches/${this.batchData?.slug}/batch-overview/order-summary`],
        {
          queryParams: {
            planId: this.upgradeData._id,
            cameFrom: BATCH_ENUMS.UPGRADE_BANNER,
          },
        }
      );
    }
    this.logGAEvent('buy_now');

    this.close();
  }

  async close(data?: string) {
    if (data === 'api') {
      await this.closeBatchUpgradeBanner();
    }
    this._dialog.close();
  }

  async closeBatchUpgradeBanner() {
    try {
      const res = await lastValueFrom(
        this._batchService.snoozeBatchUpgragePopUpSnooze(this.batchData?._id)
      );
    } catch (err) {
      console.error(err);
    }
  }
  logEvent() {
    const event = {
      batch_id: this.batchData?._id,
      batch_name: this.batchData?.name,
      page_name: 'all_classes',
      cohort_id: this.cohortService.getCohortIdFromLocal(),
      upgrade_price: this.upgradeData?.total,
      discount: this.upgradeData?.discount,
      user_segment:
        this.userSegmentGA === 'nobatch' ? 'no_batch' : this.userSegmentGA,
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'false'
          ? 'online'
          : 'offline',
    };
    this._firebaseAnalytics.logEvents(
      BATCH_PLUS_EVENT.LEVEL_UP_TO_INFINITY,
      event,
      true,
      true,
      true,
      true
    );
  }

  logGAEvent(action: 'buy_now' | 'explore_infinity_plan') {
    const event = {
      batch_id: this.batchData?._id,
      batch_name: this.batchData?.name,
      page_name: 'all_classes',
      cohort_id: this.cohortService.getCohortIdFromLocal(),
      user_segment:
        this.userSegmentGA === 'nobatch' ? 'no_batch' : this.userSegmentGA,
      action: action,
      upgrade_price: this.upgradeData?.total,
      discount: this.upgradeData?.discount,
      user_type:
        this.globalService.getIsPathShalaFromStorage() === 'false'
          ? 'online'
          : 'offline',
    };
    this._firebaseAnalytics.logEvents(
      BATCH_PLUS_EVENT.LEVEL_UP_TO_INFINITY_ACTION,
      event,
      true,
      true,
      true,
      true
    );
  }
}
