import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConfirmLogoutDialogComponent } from 'src/app/common/components/confirm-logout-dialog/confirm-logout-dialog.component';
import { GlobalObjectService } from 'src/app/common/services/global-object/global-object.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import {
  ApplicationTypes,
  IFrameActions,
  IFrameMessageProps,
  WebinarService,
} from 'src/app/common/services/webinars/webinar.service';
import { PLAY_STORE_URL } from 'src/app/constants/app.constant';
import { IFRAME_LINK } from 'src/app/enum/iframe-url.enum';
import { BOY_IMG, GIRL_IMG, PLAYSTORE_IMG } from 'src/app/core/assets.location';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pw-centres-webinar',
  templateUrl: './pw-centres-webinar.component.html',
  styleUrls: ['./pw-centres-webinar.component.scss'],
})
export class PwCentresWebinarComponent implements OnInit, OnDestroy {
  isSideNavOpen: boolean;
  urlArray: IFrameMessageProps[] = [];
  isLoaded: boolean = true;
  url = environment.VP_IFRAME_BASE_URL as string;
  iframe: any;
  webinarPageSub: Subscription;
  playstoreImg = PLAYSTORE_IMG;
  boyImg = BOY_IMG;
  girlImg = GIRL_IMG;
  userInfo: any;
  shouldShowProfile: boolean = true;
  origin = '';
  query: any;
  activatedRouteSub: Subscription;
  sidenavSubs: Subscription;
  centreK8: string = '';

  constructor(
    private webinarService: WebinarService,
    private globalService: GlobalService,
    private globalObject: GlobalObjectService,
    public router: Router,
    private dialog: MatDialog,
    private storageService: StorageService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) {
    this.centreK8 = this.storageService.getCameFromK8();
    this.activatedRoute.queryParamMap.subscribe((query: any) => {
      if (query?.params?.url) {
        this.query = query.params.url;
      }
    });
    this.getUserInfo();
    const data = this.storageService.getWebinarPageArray() || [];
    if (data.length > 0) {
      this.urlArray = data;
    }
    if (this.urlArray.length === 0) {
      const data: IFrameMessageProps = {
        action: IFrameActions.CHILD_NAVIGATION,
        value: '/vp/webinars',
        applicationType: ApplicationTypes.PW_WEBINARS,
      };
      this.urlArray.push(data);
    }
    this.origin = this.globalObject.windowObj.location.hostname;
  }

  ngOnInit(): void {
    if (
      this.storageService.getCameFromK8() === 'true' &&
      this.activatedRoute.snapshot.queryParamMap.get('cameFrom') ===
        'scholarship'
    ) {
      this.sidenavSubs = this.globalService.showSideNav$.subscribe((res) => {
        if (res) {
          this.globalService.openSideNav(false);
        }
      });
    } else {
      setTimeout(() => {
        this.globalService.showSideNav$.next(true);
      }, 850);
    }
    this.globalObject.windowObj.addEventListener('message', this.eventHandler);
  }

  ngOnDestroy(): void {
    this.webinarPageSub?.unsubscribe();
    this.activatedRouteSub?.unsubscribe();
    this.globalObject.windowObj.removeEventListener(
      'message',
      (event: MessageEvent) => {}
    );
    this.sidenavSubs?.unsubscribe();
  }

  /* Toggling Side Navigation */
  toggleSideNav() {
    if (this.isSideNavOpen) {
      this.globalService.openSideNav(this.isSideNavOpen);
    } else {
      this.globalService.openSideNav(!this.isSideNavOpen);
    }
  }

  showLottie() {
    return (
      this.router.url.includes('/batches') &&
      this.userInfo['profileId']['wallet'] > 0
    );
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }

  handleLogout() {
    const dialogRef = this.dialog.open(ConfirmLogoutDialogComponent, {
      panelClass: 'test-submit-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'yes') {
        this.logoutUser();
      }
    });
  }

  logoutUser() {
    this.globalService.logoutUserGlobally('absolute', true);
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.isLoaded = false;
  }

  goBack() {
    const storageData = this.storageService?.getWebinarPageArray() || [];
    if (
      this.storageService.getCameFromK8() === 'true' &&
      this.activatedRoute.snapshot.queryParamMap.get('cameFrom') ===
        'scholarship'
    ) {
      const queryParams = {
        cameFrom: 'k8',
      };
      this.router.navigate(['pw-scholarship'], { queryParams });
      return;
    } else if (
      this.activatedRoute?.snapshot?.queryParamMap?.get('cameFrom') ===
      'scholarship'
    ) {
      this.router.navigate(['pw-scholarship']);
    } else if (this.activatedRoute.snapshot.queryParamMap.get('cameFrom')) {
      this.location.back();
    }
    if (storageData?.length > 0) {
      this.urlArray = [...storageData];
    }
    if (this.urlArray?.length > 1) {
      let childData = this.urlArray[this.urlArray.length - 2];
      if (childData.value.includes('/vp')) {
        childData.value = childData.value.replace('/vp', '');
      }
      this.iframe.contentWindow.postMessage(childData, '*');
      this.urlArray.pop();
      this.webinarService.setWebinarPageArray(this.urlArray);
    } else if (
      this.activatedRoute?.snapshot?.queryParamMap?.get('cameFrom') ===
      'scholarship'
    ) {
      this.router.navigate(['pw-scholarship']);
      this.webinarService.removeWebinarPageArray();
    } else {
      this.webinarService.removeWebinarPageArray();
      this.router.navigate(['pw-centres']);
    }
  }

  eventHandler = (event: MessageEvent<IFrameMessageProps>) => {
    if (
      event.data.action === IFrameActions.PARENT_NAVIAGTION &&
      event.data.applicationType === ApplicationTypes.PW_WEBINARS &&
      event.data.value.includes('ai-guru')
    ) {
      this.router.navigate(['ai-guru']);
    }
    if (
      event.data.action === IFrameActions.PARENT_NAVIAGTION &&
      event.data.applicationType === ApplicationTypes.PW_WEBINARS
    ) {
      if (event?.data?.value && event?.data?.value?.length > 0) {
        const valueObj = JSON.parse(event?.data?.value);
        this.router.navigateByUrl(valueObj?.path);
      }
    }

    if (
      event.data.action === IFrameActions.CHILD_NAVIGATION &&
      event.data.applicationType === ApplicationTypes.PW_WEBINARS
    ) {
      const pathArray: string[] = [];
      this.urlArray.forEach((item: IFrameMessageProps) => {
        pathArray.push(item.value);
      });

      if (
        !pathArray.includes(event.data.value) &&
        !event.data.value.includes('isAuthenticated')
      ) {
        this.urlArray.push(event.data);
        this.webinarService.setWebinarPageArray(this.urlArray);
      }
    }
  };

  openPlaystore() {
    this.globalObject.window!.open(PLAY_STORE_URL, '_system');
  }

  goToPurchase() {
    this.router.navigateByUrl('/my-purchase?came_from=my_profile_click');
  }

  getUserInfo() {
    this.userInfo = this.globalService.getUser().getValue() || {};
    const storageData = this.storageService.getWebinarPageArray() || [];
    if (storageData?.length > 1) {
      this.url += `${storageData[storageData?.length - 1].value}`;
    } else if (this.query) {
      let decodeUrl = decodeURIComponent(this.query);
      this.url = decodeUrl;
    } else {
      this.url = this.url + '/vp/webinars';
    }
  }
}
