<!-- <p>filter-modal works!</p> -->
<section
  class="modal"
  [ngClass]="{ 'samsung-modal': coming_source === 'samsung_tv' }"
>
  <div class="filter-modal p-6" *ngIf="batchList.length > 0">
    <div class="header flex justify-between">
      <span class="title">Filter</span>
      <img
        class="cursor-pointer"
        src="assets/study/crossicon.svg"
        alt=""
        (click)="closeModal()"
      />
    </div>

    <div class="class-div mt-4 flex flex-col overflow-hidden">
      <div
        class="flex flex-col overflow-hidden"
        [ngClass]="{ tall: !checkIfMahapack(), short: checkIfMahapack() }"
      >
        <span class="class-title my-3">Batch</span>
        <div
          infinite-scroll
          class="class-content overflow-y-scroll flex flex-wrap max-h-full gap-3 mt-2"
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="100"
          (scrolled)="loadMore()"
          [scrollWindow]="false"
          (scroll)="onScroll($event)"
        >
          <button
            [ngClass]="{ clicked: selectedBatch === i }"
            class="class-name p-2 cursor-pointer"
            (click)="selectBatch(i)"
            *ngFor="let batch of batchList; let i = index"
          >
            {{ batch.name }}
          </button>
        </div>
      </div>

      <div
        class="flex-1 flex flex-col overflow-hidden"
        *ngIf="checkIfMahapack()"
      >
        <span class="class-title my-3">Select your Mahapack batch</span>
        <div
          infinite-scroll
          class="class-content overflow-y-scroll max-h-full flex flex-wrap gap-3 mt-2"
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="100"
          (scrolled)="loadMoreMahapackBatches()"
          [scrollWindow]="false"
          (scroll)="onScroll($event)"
          *ngIf="mahapackBatchList.length > 0"
        >
          <button
            [ngClass]="{ clicked: selectedMahapackBatch === i }"
            class="class-name p-2 cursor-pointer"
            (click)="selectMahapackBatch(i)"
            *ngFor="let mahapackBatch of mahapackBatchList; let i = index"
          >
            {{ mahapackBatch.batchDetail?.name }}
          </button>
        </div>
      </div>

      <div
        class="text-center w-full"
        *ngIf="processing || mahapackBatchProcessing"
      >
        <div class="loader font-bold">
          Loading<span class="loader__dot">.</span
          ><span class="loader__dot">.</span><span class="loader__dot">.</span>
        </div>
      </div>
    </div>

    <!-- <div class="doubt-div mt-4">
      <span class="doubt-title">Doubts</span>
      <div class="doubt-content flex flex-wrap gap-3 mt-3">
        <span
          [ngClass]="{ clicked: selectedDoubt === i }"
          class="doubt-name p-2 cursor-pointer"
          *ngFor="let status of statusArray; let i = index"
          (click)="selectDoubt(i)"
          >{{ status }}</span
        >
      </div>
    </div> -->

    <div class="hr-line my-6"></div>

    <div
      class="bottom flex flex-col gap-2 justify-end items-center sm:flex-row"
    >
      <!-- <span class="filter-selected">
        <span *ngIf="count > 0">{{ count }} Filter Selected</span>
      </span> -->

      <button class="reset cursor-pointer" (click)="reset()">Reset</button>
      <button
        class="apply cursor-pointer"
        [disabled]="disableDoubt()"
        (click)="gotoDoubts()"
      >
        Apply
      </button>
    </div>
  </div>

  <div
    class="no-modal flex flex-col items-center justify-center gap-4"
    *ngIf="batchList.length === 0"
  >
    <img src="assets/study/lock.svg" alt="" />
    <span class="title">Locked!</span>
    <span class="text">This Facility is available only for paid batches</span>
    <button class="explore-btn mt-4" (click)="exploreBatch()">
      Explore Batches
    </button>
  </div>
</section>
