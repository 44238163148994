import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { APP_VERSION } from '../../../constants/global-constant/global-constant.service';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { Observable, Subject, switchMap, throwError } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';
import { GlobalService } from '../global/global.service';
import { ORGANIZATION_ID } from '../../../constants/app.constant';
import { Router } from '@angular/router';
import { UnderMaintenanceService } from '../under-maintenance/under-maintenance.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  APP_VERSION: string;
  client_type: string;
  random_id: string;
  env = environment;
  private navigationSubject = new Subject<void>();

  constructor(
    private authService: AuthService,
    private globalService: GlobalService,
    private storage: StorageService,
    private router: Router,
    private underMaintenaceService: UnderMaintenanceService
  ) {
    // this.setupNavigationDebounce();
  }

  intercept<T>(
    req: HttpRequest<T>,
    next: HttpHandler
  ): Observable<HttpEvent<T>> {
    // this.authService.checkTokenExpiry();
    const token = this.authService.getTokenAsValue();

    let authReq = this.setHeaders(req, token);

    return next.handle(authReq).pipe(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      catchError((res) => {
        const url = res.url || '';
        if (res instanceof HttpErrorResponse) {
          this.storage.setResponceCode(res.status);

          // if (res.status === 503 || res.status === 504) {
          //   this.underMaintenaceService.getStatus().then(() => {
          //     this.checkRedirectionLogicForMaintenance();
          //   });
          // }

          if (res.status === 401) {
            if (url.includes('refresh-token')) {
              this.logoutUser();
              return;
            } else {
              return this.authService.refreshToken().pipe(
                switchMap(() => {
                  if (!localStorage.getItem('TOKEN_VERIFIED')) {
                    window.location.reload();
                  }
                  req = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${localStorage.getItem('TOKEN')}`,
                    },
                  });
                  return next.handle(req);
                })
              );
            }
          } else {
            return throwError(res);
          }
        } else {
          return throwError(res);
        }
      }),
      finalize(() => {})
    );
  }

  // checkRedirectionLogicForMaintenance() {
  //   const outageFlag = this.underMaintenaceService.getOutageFlagValue();
  //   if (outageFlag) {
  //     if (this.router.url !== '/batches/study') {
  //       // Trigger the debounced navigation
  //       this.navigationSubject.next();
  //     }
  //   }
  // }

  setHeaders(req: HttpRequest<any>, token: string) {
    if (req.url.includes(this.env.UNDER_MAINTENANCE)) {
      return req.clone();
    } else {
      this.storage.setRequestUrl(req.urlWithParams);
      const purchaseSource =
        this.storage.getPurchaseSource() ||
        this.globalService.purchaseSource$.getValue() ||
        '';
      if (purchaseSource) {
        this.storage.setPurchaseSource(purchaseSource);
      }
      const headers: any = {};
      if (token && token.length) {
        headers['Authorization'] = 'Bearer ' + token;
      }
      return req.clone({
        setHeaders: {
          ...headers,
          'client-id': ORGANIZATION_ID,
          'client-version': APP_VERSION,
          'Client-Type': 'WEB',
          randomId: this.globalService.getRandomId(),
          'integration-with': purchaseSource,
        },
      });
    }
  }

  async logoutUser() {
    await this.globalService.logoutUserGlobally('absolute');
    return;
  }

  // private setupNavigationDebounce() {
  //   this.navigationSubject
  //     .pipe(
  //       debounceTime(10) // Adjust this value as needed (e.g., 1000ms = 1 second)
  //     )
  //     .subscribe(() => {
  //       this.router.navigate(['/batches/study']);
  //     });
  // }
}
