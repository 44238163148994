import {
  PPApiTokenStorageType,
  PPApiTokenType,
} from 'src/app/common/api/api.enum';
import { PPApiConfig, PPApiHeaders } from 'src/app/common/api/api.type';
import { ORGANIZATION_ID } from '../app/constants/app.constant';
import { IFRAME_LINK } from 'src/app/enum/iframe-url.enum';

const BASE_URL = 'https://api.penpencil.co/';
const SOCKET_URL = 'https://socket-prod.penpencil.co/v1';
const MQTT_URL = 'wss://emqx.penpencil.net/mqtt';
export const K8_URL = 'https://k8-prod.penpencil.co/';

export enum KEYS {
  VIDEO_ENCRYPTION_KEY = 'pw3c199c2911cb437a907b1k0907c17n',
  INITIALISATION_VECTOR = '5184781c32kkc4e8',
}

// const BASE_URL = 'https://dev-api.penpencil.co/';
// const SOCKET_URL = 'https://socket-dev.penpencil.co/v1';
//const ASK_DOUBT_SOCKET_URL = 'wss://search-stage.penpencil.co/ws';
// export const K8_URL = 'https://k8-prod.penpencil.co/';

// const BASE_URL = 'https://stage-api.penpencil.co/';
// const SOCKET_URL = 'https://socket-staging.penpencil.xyz/';
// export const K8_URL = 'https://k8-stage.penpencil.co/';

const ASK_DOUBT_SOCKET_URL = 'wss://search.penpencil.co/ws';
const LIVEKIT_URL = `https://meet.pw.live`;
const VIDEO_PLAYER_NEW = 'https://player.pw.live';
const VIDEO_PLAYER_UPDATED = 'https://pw-video-player-pre-prod.pw.live';
const VIDEO_PLAYBACK_NEW = 'https://video-player.pw.live';
const webUrl = 'https://pw.live/study/saarthi';
const apiConfig: PPApiConfig = {
  baseUrl: BASE_URL,
  headers: new PPApiHeaders()
    .append('client-id', ORGANIZATION_ID)
    .append('client-type', 'WEB'),

  tokenKey: 'pp-token',
  tokenType: PPApiTokenType.BEARER,
  storedIn: PPApiTokenStorageType.LOCAL_STORAGE,
};

export enum EXTERNAL_URLS {
  MARKET_PLACE = 'https://store.pw.live/',
}

export const environment = {
  runningEnvironment: 'production',
  production: true,
  isMQTTEnabled: true,
  isSaarthiMqttEnabled: false,
  MQTT_URL,
  baseUrl: BASE_URL,
  apiConfig,
  socketUrl: SOCKET_URL,
  askDoubtSocketUrl: ASK_DOUBT_SOCKET_URL,
  videoPlayerUrl: VIDEO_PLAYER_NEW,
  updatedVideoPlayerUrl: VIDEO_PLAYER_UPDATED,
  videoPlaybackUrl: VIDEO_PLAYBACK_NEW,
  RAZORPAY_KEY: 'rzp_test_HmHjXR7TgnKpXT',
  livekitUrl: LIVEKIT_URL,
  // assetsPath: '/assets',
  assetsPath: '/study/assets',
  webUrl: webUrl,
  // serviceWorker: 'firebase-messaging-sw.js',
  // ngswWorker: '/ngsw-worker.js',
  ngswWorker: '/study/ngsw-worker.js',
  serviceWorker: '/study/firebase-messaging-sw.js',
  skillsUrl: `https://accounts.pwskills.com/signin`,
  skillsDomain: 'pwskills.com',
  pwCenterUrl: `https://centers.pw.live`,
  yogyaUrl: `https://yogya.finz.live/authenticate`,
  firebase: {
    apiKey: 'AIzaSyApsQyaMPr0NBBXi9OR7x7y0Kbc5qfLNc0',
    authDomain: 'physics-wallah-65ada.firebaseapp.com',
    databaseURL: 'https://physics-wallah-65ada.firebaseio.com',
    projectId: 'physics-wallah-65ada',
    storageBucket: 'physics-wallah-65ada.appspot.com',
    messagingSenderId: '252537344732',
    appId: '1:252537344732:web:59fbcdbca9e85979c4da19',
    measurementId: 'G-9RN512817no',
  },
  pwRedirectUrl: 'https://pw.live/',
  communityUrl: '/community/',
  SAARTHI_LINK: `https://saarthi-v2.pw.live`,
  TEST_SERIES_LINK: 'https://test-series.pw.live',
  REEL_URL_DEV: 'https://dev.physicswallah.live/micro-learning',
  REEL_URL_STAGE: 'https://staging.physicswallah.live/micro-learning',
  REEL_URL_PROD: 'https://pw.live/micro-learning',
  SAARTHI_INRO_VIDEOS_FAQ_ID: '63907804c36b7e02adcafc2f',
  // TEST_SERIES_LINK: 'http://localhost:3000',
  IFRAME_LINK: IFRAME_LINK.IFRAME_BASE_URL,
  // TEST_SERIES_LINK: 'http://localhost:3000',
  SAMSUNG_REDIRECT_LINK: 'https://samsung.pw.live',
  SENTRY_AUTH_TOKEN:
    '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',
  INFINITE_PRACTICE_LINK: 'https://pw-infinite-practise.pw.live',
  MENTORSHIP_LINK: 'https://pw-mentorship.pw.live',
  HELP_CENTRE_LINK: 'https://help-centre.pw.live',
  IS_WEB_DOWN_PROD: 'is_service_down_web',
  FBT_PAYMENT_LINK: 'https://payment.pw.live',
  IFRAME_URLS: IFRAME_LINK,
  BATTLE_GROUND_LINK: 'https://pw-battle-ground.pw.live/',
  SAFARI_CDN_1: 'sec1.pw.live',
  SAFARI_CDN_2: 'sec2.pw.live',
  PLAYER_LINK: 'https://www.pw.live/watch',
  PREMIUM_WEB_RTC: 'https://www.pw.live/classroom/pw-live-class/',
  UNDER_MAINTENANCE: 'https://maintenance.penpencil.co/',
  FIREBASE_WHATSAPP_OTP_KEY: 'whatsapp_otp_disabled_web',
  FIREBASE_OTP_TIMER_KEY: 'otp_timer_prod',
  FIREBASE_VOICE_OTP_KEY: 'voice_call_enabled_web',
  RUN_NEW_FBT: 'run_new_fbt_flow_web',
  newTestSeries: {
    enableNewTestSeriesFromHamburger: true,
    enableNewTestSeriesFromBatches: true,
  },
  WIDGETS_URL: 'https://widgets.pw.live/redirection',
  SAHAYAK_LINK: 'https://sahayak.pw.live',
  FBT_V2_PAYMENT_LINK: 'https://payment-new.pw.live',
  PAYMENT_MF_LINK: 'https://payment-mf.pw.live',
  LEARN_2_EARN_LINK: 'https://pw-learn-to-earn.pw.live/',
  AI_EVALUATION_LINK: 'https://pw-ai-evaluation.pw.live',
  LEAD_FORMS_LINK: 'https://forms-fe.pw.live',
  VP_IFRAME_BASE_URL: 'https://pw.live',
  LIBRARY_REVAMP: 'https://library.pw.live',
  BHARAT_SHARE_BANNER: 'bharat_share_banner',
  SPD_LINK: 'https://spd.pw.live',
  SPD_LINK_MF: 'https://spd-mf.pw.live',
  PW_SKILLS_URL: 'https://pwskills.com',
  PW_SKILL_BASE_URL: 'https://api.pwskills.com',
};
