import { Injectable } from '@angular/core';
import { PPApiService } from '../../api/api.service';
import { catchError, map, Observable, retry } from 'rxjs';
import { PPApiOptions, PPApiParams } from '../../api/api.type';
import { ShowErrorService } from '../showError/show-error.service';

@Injectable({
  providedIn: 'root',
})
export class EditprofileService {
  constructor(
    private apiService: PPApiService,
    private showErrorService: ShowErrorService
  ) {}

  updateProfile(data: any): Observable<any> {
    const options: PPApiOptions = {
      apiPath: `v1/users`,
    };
    return this.apiService.put<any>(data, options).pipe(
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }

  uploadFile(file: any) {
    const options: PPApiOptions = {
      apiPath: 'v1/files',
    };

    return this.apiService.post(file, options).pipe(
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }

  getStates(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/organizations/fetch-states`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }

  getCityStatesByPincode(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/organizations/fetch-state-by-pincode`,
      params: new PPApiParams().appendAll(query),
    };
    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }

  getCitites(query?: any) {
    const options: PPApiOptions = {
      apiPath: `v2/organizations/fetch-cities`,
      params: new PPApiParams().appendAll(query),
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      retry(1),
      catchError((err) => this.showErrorService.showError(err))
    );
  }
}
