export enum GAEvents {
  LIBRARY_HOW = 'library_how',
  LIBRARY_EXPLORE = 'library_explore',
  SEARCH_CLICK = 'search_click',
  LIBRARY_FILTER = 'library_filter',
}

export enum VIEW_SOLUTIONS {
  VIEW_SOLUTION = 'view_solutions',
}

export enum WEEKLY_SCHEDULE {
  CALENDAR_NAVIGATE = 'calendar_navigate',
  SCHEDULES_CLICK = 'schedules_click',
  ATTACHMENT_POPUP_ACTION = 'attachement_popup_action',
}

export enum UPDATE_NUMBER_EVENTS {
  EDIT_PROFILE_PAGE_VIEW = 'edit_profile_page_view',
  EDIT_PHONE_NUMBER = 'edit_phone_number',
  OLD_PHONE_REQUEST_OTP = 'old_phone_request_otp',
  VERIFY_OLD_OTP = 'verify_old_otp',
  NEW_PHONE_REQUEST_OTP = 'new_phone_request_otp',
  VERIFY_NEW_OTP = 'verify_new_otp',
}

export enum STUDY_PAGE_EVENT {
  STUDY_PAGE_VISIT = 'study_page_visit',
  WEEKLY_SCHEDULE_CLICK = 'weekly_schedule_click',
  WEEKLY_SCHEDULE_FILTER = 'weekly_schedule_filter',
  VIDEO_CLICK = 'video_click',
  VIEW_ALL_CLASSES = 'view_all_classes',
  SUBJECT_CLICK = 'subject_click',
  SUBJECT_CHAPTER_CLICK = 'subject_chapter_click',
  RECOMMEND_BATCH_ACTION = 'recommend_batch_action',
  DOUBT_CLICK = 'doubt_click',
  VIDEO_ACTION = 'video_action',
  ITEM_VIEW = 'item_view',
  BATCH_CHANGE_ACTION = 'batch_change_action',
}

export enum UNDER_MAINTENANCE_PAGE_EVENTS {
  UNDER_MAINTENANCE_SCREEN = 'under_maintain_screen',
  MAINTENANCE_SC_LIVE_LECTURES = 'maintain_sc_live_lectures',
  MAINTENANCE_SC_LIVE_LECTURES_CLICKED = 'maintain_sc_live_lecture_clicked',
}

export enum ADMISSION_JOURNEY {
  BATCH_DESCRIPTION_LOAD = 'batch_description_load',
  VP_DESCRIPTION_PAGE_SAT_CTA_CLICK = 'vp_description_page_sat_cta_click',
  VP_BOOK_SEAT = 'vp_book_seat',
  PROCEED_TO_BUY = 'proceed_to_buy',
  VP_BATCH_REGISTRATION_FORM = 'vp_batch_registration_form',
  VP_PAY_CTA = 'vp_pay_cta',
  VP_PAYMENT_CONFIRMATION_PAGE = 'vp_payment_confirmation_page',
  VP_RECEIPT_CTA = 'vp_receipt_cta',
  VP_ADMISSION_FORM = 'vp_admission_form',
}

export enum CONTACT_US_EVENTS {
  CONTACT_US_VISIT = 'contact_us_visit',
  MY_ISSUES = 'my_issues',
  MY_ISSUES_FILTER = 'my_issues_filter',
}

export enum BATCH_PLUS_EVENT {
  PLUS_COMPARE_POPUP = 'plus_compare_popup',
  PLUS_POPUP_ACTION = 'plus_popup_action',
  LEVEL_PLUS = 'levelup_plus',
  LEVELUP_PLUS = 'levelup_plus',
  COMPARE_PLANS_BUTTON = 'compare_plans_button',
  KNOW_MORE_CLICK = 'know_more_click',
  KNOW_MORE_ACTION = 'know_more_action',
  UNLOCK_MORE = 'unlock_more',
  UNLOCK_MORE_ACTION = 'unlock_more_action',
  LEVEL_UP_TO_INFINITY_ACTION = 'level_up_to_infinity_action',
  BUY_NOW_CLICKED = 'buy_now_clicked',
  LEVEL_UP_TO_INFINITY = 'level_up_to_infinity',
  UNLOCK_POPUP = 'unlock_popup',
  SUBJECTIVE_TEST_PAGE = 'subjective_test_page',
  OBJECTIVE_TEST_PAGE = 'objective_test_page',
}

export const MY_PROFILE = 'myprofile';
export const LOGOUT = 'logout';
export const PLAY_STORE_DOWNLOAD = 'playstore_download';
export const LOGIN_PAGE_VISIT = 'login_page_visit';
export const EXAM_DETAILS_PAGE_VISIT = 'exam_details_page_visit';
export const EXAM_DETAILS_PAGE_SUBMIT = 'exam_details_page_submit';
export const MOBILE_NUMBER_CLICK = 'mobile_number_click';
export const CONTINUE_CLICK = 'continue_click';
export const BATCH_EXPLORE_DESCRIPTION = 'batch_explore_description';
export const BATCH_ANNOUNCMENT = 'batch_announcement';
export const MY_BATCH_LETS_STUDY_DESCRIPTION = 'mybatch_letsstudy_description';
export const BATCH_ANNOUNCEMENT_CLICK = 'batch_announcement_click';
export const BATCH_SUBSCRIBED = 'BATCH_SUBSCRIBED';
export const BATCH_EXPLORE_DESCRIPTION_BUYNOW =
  'batch_explore_description_buynow';
export const BATCH_DESCRIPTION_DEMO_VIDEO = 'batch_description_demovideo';
export const MY_BATCH_DESCRIPTION_VIEW_ALL =
  'mybatch_description_faculties_view_all';
export const BATCH_DESCRIPTION_VIEW_ALL =
  'batch_description_faculties_view all';
export const MY_BATCH_DESCRIPTION_FAQ = 'mybatch_description_faq';
export const MY_BATCH_LETS_STUDY_DESCRIPTION_FACULTY_VIEW_MORE =
  'mybatch_letsstudy_description_faculty_viewmore';
export const BATCH_DESCRIPTION_FACULTIES_VIEWMORE =
  'batch_description_faculties_viewmore';
export const MY_BATCH_CLASSROOM_ALL_CLASSES_SUBJECTS =
  'mybatch_classroom_all_classes_subjects';
export const BATCH_CLASSROOM_ALL_CLASSES_SUBJECTS =
  'batch_classroom_allclasses_subjects';
export const MY_BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT =
  'mybatch_classroom_weekly_schedule_subject';
export const BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT =
  'batch_classroom_weekly_schedule_subject';
export const MY_BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT_ATTACHMENTS =
  'mybatch_classroom_weekly_schedule_subject_attachments';
export const BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT_ATTACHMENTS =
  'batch_classroom_weekly_schedule_subject_attachments';
export const MY_BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT_STARTCLASS =
  'mybatch_classroom_weekly_schedule_subject_startclass';
export const BATCH_CLASSROOM_WEEKLY_SCHEDULE_SUBJECT_STARTCLASS =
  'batch_classroom_weekly_schedule_subject_startclass';
export const FREE_PRODUCT_PURCHASE = 'free_product_purchase';
export const BATCHES_PAGE_VISIT = 'batches_page_visit';
export const BATCHES_SEARCHBAR = 'batches_searchbar';
export const BATCHES_FILTER = 'batches_filter';
export const BATCH_EXPLORE_DESCRIPTION_BUYNOW_APPLY_COUPON =
  'batch_explore_description_buynow_applycoupon';
export const BATCH_DESCRIPTION_REFER_NOW = 'batch_description_refer now';
export const BOOK_ADDED = 'book_added';
export const BATCH_EXPLORE_DESCRIPTION_BUY_NOW_FBT_ADD =
  'batch_explore_description_buy_now_fbt_Add';
export const ADDRESS_ENTERED = 'address_entered';
export const PAY_NOW_CLICKED = 'pay_now_clicked';
export const BATCH_CLASSROOM_ALLCLASSES_SUBJECT_ALLCONTENT_NOTES =
  'batch_classroom_allclasses_subject_allcontent_notes';
export const MY_BATCH_CLASSROOM_ALL_CLASSES_SUBJECT_ALLCONTENTS_DPPS =
  'mybatch_classroom_all_classes_subject_allcontent_dpps';
export const BATCH_CLASSROOM_ALLCLASSES_SUBJECT_ALLCONTENT_DPPS =
  'batch_classroom_allclasses_subject_allcontent_dpps';
export const MY_BATCH_CLASSROOM_ALLCLASSES_SUBJECT_ALL_CONTENT_LECTURES =
  'mybatch_classroom_all_classes_subject_all_content_lectures';
export const BATCH_CLASSROOM_ALLCLASSES_SUBJECT_ALL_CONTENT_LECTURES =
  'batch_classroom_all_classes_subject_all_content_lectures';
export const BOOKMARK_SUBJECT = 'bookmark_subject';
export const BOOKS_EXPLORE_BUYNOW_ORDERSUMMARY_EDIT_ADDRS =
  'books_explore_buynow_ordersummry_edit_addrs';
export const BOOKS_EXPLORE_BUYNOW_ORDERSUMMARY_BUY_NOW =
  'books_explore_buynow_ordersummry_buynow';
export const BOOKS_PAGE_VISIT = 'books_page_visit';
export const BOOK_ORDERS = 'books_orders';
export const BOOK_EXPLORE = 'books_explore';
export const BOOKS_EXPLORE_BUYNOW_ORDERSUMMARY_BUYNOW_RAZORPAY =
  'books_explore_buynow_ordersummry_buynow_razorpay';
export const BOOK_CLICK = 'book_click';
export const BOOKS_EXPLORE_BUYNOW = 'books_explore_buynow';
export const HOME_CLICKED = 'home_clicked';
export const HOMEPAGE_VISIT = 'home_pagevisit';
export const HOME_SUBJECT_SEEALL = 'home_subject_seeall';
export const HOME_INVITE = 'home_invite';
export const HOME_PATHSHALA_LEARNMORE = 'home_pthshala_learnmore';
export const PATHSHALA_PAYFEE = 'pthshala_payfee';
export const PATHSHALA_GET_ENROLLED = 'pthshala_getenrolled';
export const PATHSHALA_SEARCH_CENTERS = 'pthshala_searchcenters';
export const PATHSHALA_COURSE_NAME_CLICK = 'pthshala_coursename_click';
export const HOME_SUBJECT_NAME = 'home_subject_name';
export const FEED_BLOG = 'feed_blog';
export const LIBRARY_VISIT = 'library_visit';
export const CUSTOMIZE_LIBRARY = 'customize_library';
export const LIBRARY_PROGRAM_SUBJECT = 'library_program_subject';
export const TEST_BUYNOW = 'test_buynow';
export const TEST_BUYNOW_PAYMENT_CHANNEL = 'test_buynow_paymentchannel';
export const TEST_BUYNOW_APPLYCOUPON = 'test_buynow_applycoupon';
export const MY_PROFILE_EDIT = 'myprofile_edit';
export const TEST_EXPLORE = 'test_explore';
export const TEST_PAGE_VISIT = 'tests_page_visit';
export const MY_WALLET_INVITENOW = 'mywallet_invitenow';

/* Saarthi Events */

//export const GET_STARTED_CLICK = 'get_started_click';
//export const PROMO_VIDEO = 'promo_video';
export const MARKETPLACE_VISIT = 'marketplace_visit';
//export const DOUBLE_PAGE_VISIT = 'double_page_visit';
//export const DOUBT_SEARCH = 'doubt_search';
//export const CONNECT_COACH_FROM_DOUBT = 'connect_coach_from_doubt';
export const CONCEPT_SEARCH = 'concept_search';
export const CONCEPT_SOLUTION_VISIT = 'concept_solution_visit';
export const PLANNER_PAGE_VISIT = 'planner_page_visit';
export const ADD_NEW_CLICK = 'add_new_click';
export const SUBMIT_GOAL_CLICK = 'submit_goal_click';
export const SUBSCRIBE_CLICK = 'subscribe_click';
export const PAY_NOW_CLICK = 'pay_now_clicked';
export const SOLUTION_VISIT = 'solution_visit';
export const VIDEO_CALL_CLICK = 'video_call_click';
export const SAARTHI_POPUP_DISPLAY = 'saarthi_popup_display';
export const SAARTHI_POPUP_ACTION = 'saarthi_popup_action';
export const CONCEPT_NOT_FOUND = 'concept_not_found';
export const SAARTHI_USER_STATUS = 'saarthi_user_status';
export const ASK_DOUBT_CLICK = 'ask_doubt_click';
export const ASK_CONCEPT_CLICK = 'ask_concept_click';
export const TRANSACTION_FAIL_SAARTHI = 'transaction_failed_SAARTHI';
export const TRANSACTION_SUCCESS_SAARTHI = 'transaction_success_SAARTHI';
export const ENTER_NAME = 'enter_name';
export const OTP_ISSUE = 'otp_issue';
export const SEND_MORE_SOLUTION = 'send_more_solution';
export const SOLUTION_VISIT_FROM_CHAT = 'solution_visit_from_chat';
export const IMAGE_DOUBT_ASKED = 'image_doubt_asked';

export const CHAT_PAGE_VISIT = 'chat_page_visit';
export const POLL_EVENT_RECEIVED = 'poll_event_received';
export const POLL_ICON_CLICKED = 'poll_icon_clicked';
export const POLL_STATUS_CHANGED = 'poll_status_changed';
export const POLL_RECEIVED = 'poll_received';
export const POLL_RECEIVED_FAILED = 'poll_received_failed';
export const FEEDBACK_PAGE_VISIT = 'feedback_page_visit';
export const FEEDBACK_RESPONSE = 'feedback_response';
export const MY_BATCHES_TEST_SERIES = 'my_batches_test_series';
export const DEMO_VIDEO_CLICK = 'demo_video_click';
export const GET_OTP = 'get_otp';
export const RESEND_OTP = 'resend_otp';
export const OTP_CALL = 'otp_call';
export const OTP_WHATSAPP = 'otp_whatsapp';
export const MY_BATCHES_SECTION = 'my_batches_section';
export const SCHEDULED_CLASSES = 'scheduled_classes';
export const RECENTLY_WATCHED = 'recently_watched';
export const MY_BATCHES_ANNOUNCEMENTS = 'my_batches_announcements';
export const TRANSACTION_SUCCESS = 'transaction_success';
export const TRANSACTION_FAIL = 'transaction_failed';
export const LOGIN_SUCCESS = 'login_success';
export const SIGNUP_SUCCESS = 'signup_success';
export const KHAZANA_ENTRY = 'khazana_entry';
export const KHAZANA_TEACHER_EXPLORE = 'khazana_teacher_explore';
export const KHAZANA_TEACHER_CLICK = 'khazana_teacher_click';
export const KHAZANA_EXPLORE = 'khazana_explore';
export const KHAZANA_DEMO = 'khazana_demo';
export const KHAZANA_UNLOCK_BUTTON = 'khazana_unlock_button';
export const KHAZANA_FAQ = 'khazana_faq';

export const STUDY_CLICK = 'study_click';
export const STUDY_CALENDER = 'study_calender';
export const STUDY_BATCH_FILTER = 'study_batch_filter';
export const STUDY_MY_SECTION = 'study_my_section';
export const MY_DOUBTS_FILTER = 'my_doubts_filter';
export const CONNECT_WITH_COACH = 'connect_with_coach';
export const ASK_DOUBT_SEARCH = 'ask_doubt_search';
export const WIDGET_CLICK = 'widget_click';
export const BANNER_CLICK = 'banner_click';
export const MESSAGE_SENT = 'message_sent';
export const YOUTUBE_CLICK = 'youtube_click';
export const GOOGLE_CLICK = 'google_click';

// Result
export const RESULT_MARKETPLACE_VISIT = 'marketplace_visit';
export const BE_A_PWPART = 'be_a_pwpart';

export const REFER_N_EARN_PAGE_VISIT = 'refer_n_earn_page_view';
export const REFER_NOW_EARN_CLICK = 'refer_now_earn_click';
export const REFER_SHARE = 'refer_share';
export const REDEEM_NOW = 'redeem_now';
export const REFER_FAQ = 'refer_faq';
export const REDEEM_TYPE = 'redeem_type';
export const COUPON_APPLY = 'coupon_apply';
export const REDEEM_NOW_CLICK = 'redeem_now_click';
export const TERMS_AND_CONDITIONS = 'terms_and_conditions';
export const TRANSFER_TO_WALLET_CLICK = 'transfer_to_wallet_click';
export const TRANSFER_TO_BANK_CLICK = 'transfer_to_bank_click';
export const VOUCHER_BUTTON_CLICK = 'voucher_button_click';
export const HOW_IT_WORKS = 'how_it_works';
export const FAQ_REFER_EARN = 'faq_refer_earn';
export const VIEW_HISTORY_BUTTON = 'view_history_button';
export const REDEEM_VOUCHER = 'R&E Redeem Voucher';

// REELS
export const REELS_START = 'reels_start';

// SCP
export const PW_LEAGUE_VISIT = 'pw_league_visit';
export const CERTIFICATE_DOWNLOAD = 'certificate_download';
export const CLAIM_PRIZE = 'claim_prize';
export const getBatchGrade = (grade: string) => `transaction_success_${grade}`;
export const getBatchExam = (exam: string) => `transaction_success_${exam}`;

export const COHORT_EVENTS = {
  COHORT_CLICK: 'cohort_click',
  TAGS_CLICK: 'tags_click',
  USER_PROFILE_DETAILS: 'user_profile_details',
  PW_COHORT_CHANGE: 'pw_cohort_change',
};

export const BATCH_DISCOVERABILITY_EVENTS = {
  BATCH_EXAM_SELECT: 'batch_exam_select',
  BATCH_BANNER_CLICK: 'batch_banner_click',
  SEARCH_CLICK: 'search_click',
  BATCH_MYWALLET: 'batch_mywallet',
  BATCH_FILTER_TYPE_VALUE: 'batch_filter_type_value',
  BATCH_SHARE: 'batch_share',
  BATCH_EXPLORE_CLICK: 'batch_explore_click',
  BUY_NOW_CLICKED: 'buy_now_clicked',
  BATCH_SUPPORT: 'batch_support',
  BATCH_DESC_CTAS: 'batch_desc_ctas',
};

// Premium/Vplive Events
export const PREMIUM_COURSE_EVENTS = {
  PREMIUM_COURSEDETAILS_ACTION_CLICK: 'premium_coursedetails_action_click',
  PREMIUM_BOOKFREESESSION_CLICK: 'premium_bookfreesession_click',
  PREMIUM_PHONENO_CLICK: 'premium_phoneno_click',
  PREMIUM_CONNECTWITHUS_CLICK: 'premium_connectwithus_click',
  PREMIUM_STUDYSECTION_CLICK: 'premium_studysection_click',
  INFINITE_LEARNING: 'infinite_learning',
  INFINITE_LEARNING_POPUP: 'infinite_learning_popup',
  INFINITE_LEARNING_POPUP_ACTION: 'infinite_learning_popup_action',
  BOOK_FREE_SESSION_ACTION: 'book_free_session_action',
  PREMIUM_FREE_SESSION_JOIN_NOW: 'premium_free_session_join_now',
  NAV_CTA_CLICK: 'pw_nav_cta_click',
  COMMUNITY_LANDING_PAGE: 'community_landing_page',
};

//pw-scholar
export const PW_SCHOLAR_VISIT = 'pw_scholar_visit';
//faq
export const FAQ_EXPLORE = 'faq_explore';

// subjective

export const TEST_CLICK = 'test_click';
export const DPP_CLICK = 'dpp_click';
export const TEST_TYPE_CLICK = 'test_type_click';
export const DPP_TYPE_CLICK = 'dpp_type_click';
export const TEST_QUESTION_PAPER = 'test_question_paper';
export const DPP_QUESTION_PAPER = 'dpp_question_paper';
export const TEST_ANSWER_SUBMIT = 'test_answer_submit';
export const DPP_ANSWER_SUBMIT = 'dpp_answer_submit';
export const TEST_RESULT_VIEW = 'test_result_view';
export const DPP_RESULT_VIEW = 'dpp_result_view';
export const TEST_RESULT_ACTION = 'test_result_action';
export const DPP_RESULT_ACTION = 'dpp_result_action';
export const DOUBT_CLICK = 'doubt_click';
export const SOLVER_PROFILE_CLICK = 'solver_profile_click';
export const STUDENT_MASTER_PROG_CHECK = 'student_master_prog_check';

//video player
export const VIDEO_PLAYER_FEATURE = 'video_player_feature';
export const MYDOUBT_SELECT = 'mydoubt_select';
export const TIMELINE_SLIDE_SELECT = 'timeline_slide_select';

//
export const CLASS_CONTENT_EXPLORE = 'class_content_explore';
export const CLASSES_CLICK = 'classes_click';
export const TEST_EXPLORE_CLICK = 'test_explore_click';
export const WEEKLY_SCHEDULE_CLICK = 'weekly_schedule_click';
export const MY_BATCH_NAVIGATION = 'mybatch_navigation';
export const PREMIUM_UPGRADE_CLICK = 'premium_upgrade_click';
export const PW_BATCH_LISTING_PAGE = 'pw_batch_listing_page';
export const CONTINUE_LEARNING = 'continue_learning';
export const ASK_DOUBT_GOOGLE = 'ask_doubt_google';
export const ASK_DOUBT_YT = 'ask_doubt_yt';

export const CENTER_PAGE_VISIT = 'centre_page_visit';

// Test Events

export const START_TEST = 'start_test';
export const REATTEMPT_TEST = 'test_reattempt';
export const TEST_VIEW_SOLUTION = 'test_view_solution';
export const TEST_SUBMIT = 'test_submit';
export const TEST_VIDEO_SOLUTION = 'test_video_solution';

// OrderSummary-Events;

export const ADDRESS_PAGE_CONTINUE = 'address_page_continue';
export const COMBO_ADDED = 'combo_added';
export const BATCH_FBT_PAGE = 'batch_fbt_page';
export const BATCH_DESCRIPTION_PAGE = 'batch_description_page';
export const EVENT_ADDED = 'event_added';
export const SAARTHI_PROCEED_TO_BUY = 'saarthi_proceed_to_buy';
export const VIEW_ALL_COUPONS = 'view_all_coupons';
export const APPLY_COUPON_CLICK = 'apply_coupon_click';

//cwoc

export const CWOC_IMAGE = 'cwoc_image';
export const NOTIFICATION_CLICK = 'notify_click';
export const YOUR_COACH_CLICK = 'your_coach_click';
export const OTHER_COACH_CLICK = 'other_coach_click';
export const POPUP_CLOSE = 'popup_close';

//LIVE CLASS EVENTS

export enum LIVE_CLASS_EVENTS {
  SOCKET_CONNECT = 'socket_connection',
}

// spd events

export const MARK_AS_COMPLETE = 'mark_as_complete';

export enum THANKU_PAGE_EVENTS {
  THANKYOU_PAGE_VISIT = 'thankyou_page_visit',
  THANKYOU_ACTION_CLICK = 'thankyou_action_click',
  ADD_ITEM = 'add_item',
  FAQ_CLICK = 'faq_click',
  BUY_NOW_CLICKED = 'buy_now_clicked',
  PROCEED_TO_PAYMENT = 'proceed_to_payment',
  TRANSACTION_SUCCESS = 'transaction_success',
  BHARAT_BATCH_SHARE = 'bharat_batch_share',
  BATCH_UPGRADE = 'batch_upgrade',
}

// FREE CONTENT EVENTS
export enum FreeContentEvents {
  FC_CARD_DISPLAYED = 'fc_card_displayed',
  FC_VIEW_ALL_CLICKED = 'fc_view_all_clicked',
  FC_PAGE_LAND = 'fc_page_land',
  FC_VIDEO_PLAY_CLICKED = 'fc_video_play_clicked',
}

export enum TestPressEvents {
  TESTPRESS_CLICK = 'testpress_click',
}

export const SIGN_UP_SUCCESS = 'sign_up_success';
export const SIGN_IN_SUCCESS = 'sign_in_success';
export const COHORT_SELECT = 'cohort_select';
export const COHORT_CHANGE = 'cohort_change';
export const BATCH_LISTING_PAGE_VIEW = 'batch_listing_page_view';
export const BATCH_BANNER_CLICK = 'batch_banner_click';
export const BATCH_DESCRIPTION_PAGE_VIEW = 'batch_description_page_view';
export const BATCH_ORIENTATION_CLICK = 'batch_orientation_click';
export const BATCH_SHARE = 'batch_share';
export const BATCH_ENROLL_SUCCESSFULL = 'batch_enroll_successfull';
export const KHAZANA_LANDING_PAGE = 'khazana_landing_page';
export const TEST_HOME_PAGE_VIEW = 'test_home_page_view';
export const TEST_DESCRIPTION_PAGE_VIEW = 'test_description_page_view';
export const TEST_BUY_NOW = 'test_buy_now';
export const BUY_NOW_CLICKED = 'buy_now_clicked';
export const PROCEED_TO_BUY = 'proceed_to_buy';
export const TRANSACTION_SUCCESS_MOENGAGE = 'transaction_success';
export const TRANSACTION_FAILED = 'transaction_failed';
export const TRANSACTION_CANCELLED = 'transaction_cancelled';
export const STUDY_PAGE_VIEW = 'study_page_view';
export const ASK_DOUBT_PAGE_VIEW = 'ask_doubt_page_view';
export const LIBRARY_PAGE_VIEW = 'library_page_view';
export const LIBRARY_SUBJECT_PAGE_VIEW = 'library_subject_page_view';
export const SAARTHI_HOME_PAGE_VIEW = 'saarthi_home_page_view';
export const SAARTHI_SUBSCRIBE_CLICK = 'saarthi_subscribe_click';
export const CENTRE_PAGE_VIEW = 'centre_page_view';
export const CONTACT_US_PAGE_VIEW = 'contact_us_page_view';
export const CHAMPION_LEAGUE_PAGE_VIEW = 'champion_league_page_view';
export const DEMO_VIDEO_CLICK_MOENGAGE = 'demo_video_click';
export const SAARTHI_SUMMARY_PAGE_VIEW = 'saarthi_summary_page_view';
export const SAARTHI_REFER_AND_EARN_SHARE = 'refer_n_earn_code_share';
export const ORDER_SUMMARY_PAGE_VIEW = 'order_summary_page_view';
export const MY_PURCHASE_PAGE_VIEW = 'my_purchase_page_view';

//FOUNDATION DONATION EVENTS
export const DONATION_CHECKBOX_CLICK = 'donation_checkbox_click';
export const DEMO_SESSION_CONFIRMATION_VPLIVE =
  'demo_session_confirmation_vplive';
export const ALL_CLASSES_LANDING = 'all_classes_landing';

// clubbing events
export const UPSC_MENTORSHIP_CLICK = 'upsc_mentorship_click';
export const ANNOUNCEMENTS = 'announcements';
export const INFINITE_PRACTICE_LANDING_PAGE = 'infinite_practice_landing_page';
export const MENTORSHIP_OPEN = 'mentorship_open';
export const COMPLEMENTARY_BATCHES_ACCESS_PAGE =
  'complementary_batches_access_page';
export const INFINITY_STORE_ITEMS_CLICK = 'infinity_store_items_click';
export const INFINITY_STORE_ITEMS_ACTION = 'infinity_store_items_action';
export const SAARTHI_PAGE_VISIT = 'saarthi_page_visit';

// Part payment events
export const PAY_NOW_PART_PAYMENT_CLICK = 'pay_now_part_payment_click';
export const CONTACT_US_VISIT = 'contact_us_visit';
export const PART_PAYMENT_POPUP = 'part_payment_popup';
export const ACTIVATION_FORM_START = 'activation_form_start';
export const PART_PAYMENT_DETAILS = 'part_payment_details';
export const BOOK_A_SEAT_CONTINUE = 'book_a_seat_continue';

// AI Evaluation events
export const AIEVALUATION_TEST_ACTION = 'aievaluation_test_action';

// Expiry Events
export const KNOW_MORE_CLICK = 'know_more_click';
export const MY_BATCHES_VISIT = 'my_batches_visit';
export const VIEW_EXPIRED_BATCHES_POPUP_RENDER =
  'view_expired_batches_popup_render';
export const VIEW_EXPIRED_BATCHES_POPUP_CLICK =
  'view_expired_batches_popup_click';
export const VIEW_SIMILAR_BATCHES = 'view_similar_batches';

// AI Personalisation event
export const AIP_PAGE_NAVIGATE = 'aip_page_navigate';
export const AIP_CLICKS = 'aip_clicks';
//Streak events
export const STREAK_CLICK = 'streak_click';

// TY page
export const TY_PAGE_CONTINUE_CLICK = 'ty_page_continue_click';
export const ENROLL_NOW_CLICK = 'enroll_now_click';
export const FORM_VISIBLE = 'form_visible';
export const FORM_ACTION = 'form_action';
export const FORM_SUBMITTED_SUCESSFULLY = 'form_submitted_sucessfully';
