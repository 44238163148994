import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class CenterPageService {
  private centrePage$: BehaviorSubject<IFrameMessageProps[]> =
    new BehaviorSubject<IFrameMessageProps[]>([]);

  constructor(private storageService: StorageService) {}

  setCentrePageArray(data: IFrameMessageProps[]) {
    this.storageService.setCenterPageArray(data);
    this.centrePage$.next(data);
  }

  get getCentrePageArray() {
    const data = this.storageService.getCenterPageArray() || [];
    if (data.length > 0) {
      this.setCentrePageArray(data);
    }
    return this.centrePage$;
  }

  removeCentrePageArray() {
    this.storageService.removeCenterPageArray();
    this.centrePage$.next([]);
  }
}
export enum IFrameActions {
  CHILD_NAVIGATION = 'CHILD_NAVIGATION',
  PARENT_NAVIAGTION = 'PARENT_NAVIAGTION',
  LOCALSTORAGE = 'LOCALSTORAGE',
  CHILD_NAVIGATION_BACK = 'CHILD_NAVIGATION_BACK',
  ABSOLUTE_ZERO = 'ABSOLUTE_ZERO',
  BACK_PW_SCHOLAR = 'BACK_PW_SCHOLAR',
  IS_PAYMENT_REDIRECTED = 'IS_PAYMENT_REDIRECTED',
  OPEN_COMPARISON_TABLE = 'OPEN_COMPARISON_TABLE',
  URL_REDIRECTION = 'URL_REDIRECTION',
  GO_BACK = 'GO_BACK',
}

export enum ApplicationTypes {
  PW_CENTRES = 'PW_CENTRES',
  PW_SPD = 'PW_SPD',
  PW_MICRO_LEARNING = 'PW_MICRO_LEARNING',
  PAYMENT_FE = 'PAYMENT_FE',
  BATCH_PLUS_TABLE = 'BATCH_PLUS_TABLE',
  PAYMENT_MF = 'PAYMENT_MF',
}

export interface IFrameMessageProps {
  action: IFrameActions;
  value: string;
  applicationType: ApplicationTypes;
  type?: string;
  newFlag?: boolean;
}
