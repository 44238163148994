<section class="schedule-class-container widget-wrapper py-8">
  <!-- <div>
    <span [innerHtml]="title || defaultHeading | safe : 'html'"></span>
  </div>

  <div>
    <span
      class="mt-2"
      [innerHtml]="subTitle | safe : 'html'"
      *ngIf="subTitle && subTitle.length > 0"
    ></span>
  </div> -->

  <section *ngIf="batchList.length > 0">
    <div class="flex gap-3 mt-3 border-b pb-2" *ngIf="!processing">
      <!-- Batch Name -->
      <app-dummy-dropdown
        [variant]="dropdownVariant.NAKED"
        [title]="selectedBatch.batch.name"
        (handleChange)="selectDropdown('batch')"
      ></app-dummy-dropdown>

      <!-- selected Day -->
      <!-- <app-dummy-dropdown
        [title]="selectedDay.name"
        (handleChange)="selectDropdown('day')"
      ></app-dummy-dropdown> -->
    </div>

    <div class="flex items-center justify-between mt-4">
      <span class="today-class-text heading-5 semibold">Today's Class</span>

      <div
        (click)="handleWeeklySchedule()"
        class="weekly-button flex items-center justify-around gap-3 cursor-pointer"
        *ngIf="!selectedBatch.isBlocked"
      >
        <img [src]="calendarImg" alt="" />
        <span class="body-2 semibold">Weekly Schedule</span>
      </div>
    </div>

    <div
      *ngIf="selectedBatch.isBlocked"
      class="h-auto mt-6 w-full flex flex-col px-4 py-8 justify-center bg-[#F8F8F8] rounded-lg"
    >
      <div class="flex justify-center items-center">
        <img [src]="blocked" class="items-center" alt="Blocked" />
      </div>
      <div class="flex flex-col items-center justify-center my-4">
        <span class="font-bold text-xl">Blocked!</span>
        <span class="text-sm font-medium text-center"
          >You have been blocked from accessing the Content of the
          <br />
          Batch for Violating Terms of Services</span
        >
      </div>

      <div class="flex justify-center">
        <div
          (click)="contactUs()"
          class="flex items-center bg-[#F1EFFF] cursor-pointer rounded-lg w-28 h-8"
        >
          <span class="font-medium pl-1 text-[#5A4BDA] text-base items-center"
            >Contact Us</span
          >
          <mat-icon class="text-[#5A4BDA]">keyboard_arrow_right</mat-icon>
        </div>
      </div>
    </div>
    <!-- schedule cards -->
    <ng-container *ngIf="!selectedBatch.isBlocked">
      <section
        class="flex items-center justify-between gap-2"
        *ngIf="todayScheduleList.length > 0"
      >
        <button
          (click)="showPrevious()"
          class="arrow-button"
          *ngIf="todayScheduleList.length > 3"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
        <div
          class="flex overflow-x-scroll no-scrollbar mt-3 w-11/12"
          #scrollContainer
        >
          <app-study-video-card
            *ngFor="let lectureData of todayScheduleList"
            [lectureData]="lectureData"
            [batchId]="batchId"
            [widget_type]="widget_type"
            [widgetId]="widgetId"
            [isOnline]="!selectedBatch.batch.isPathshala"
            variant="study"
            [batchDetail]="batchDetail"
            [upcomingInstalment]="upcomingInstalment"
            [pageName]="pageName"
            [entryPointComponent]="'BATCH_TODAYS_CLASS_VIDEOS'"
          >
          </app-study-video-card>
        </div>
        <button
          (click)="showNext()"
          class="arrow-button"
          *ngIf="todayScheduleList.length > 3"
        >
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </section>

      <div
        class="flex overflow-scroll no-scrollbar w-full gap-3 mt-3"
        *ngIf="todayScheduleList.length === 0 && subProcessing === true"
      >
        <ngx-shimmer-loading
          *ngFor="let item of [1, 1, 1, 1, 1]"
          class="col-span-1"
          shape="rect"
        ></ngx-shimmer-loading>
      </div>
      <div
        class="no-class-found flex flex-col vertical-align rounded-xl py-4"
        *ngIf="
          batchList.length > 0 &&
          todayScheduleList.length === 0 &&
          !subProcessing &&
          selectDay !== day.YESTERDAY
        "
      >
        <span class="text-lg bold-1">Classes not Scheduled yet</span>
        <!-- <p class="px-2">
        No classes have been scheduled for
        {{ selectDay }} yet. You can see your upcoming class as soon as it gets
        scheduled.
      </p> -->
      </div>
      <div
        class="no-class-found flex flex-col vertical-align mt-1.5 md:mt-4 rounded-xl py-4"
        *ngIf="
          batchList.length > 0 &&
          todayScheduleList.length === 0 &&
          !subProcessing &&
          selectDay === day.YESTERDAY
        "
      >
        <span class="text-lg bold-1">No Classes Scheduled</span>
        <!-- <p class="px-2">
        No classes have been scheduled for
        {{ selectDay }} yet. You can see your upcoming class as soon as it gets
        scheduled.
      </p> -->
      </div>
    </ng-container>
    <div
      *ngIf="!selectedBatch.isBlocked"
      class="flex items-center justify-center w-full cursor-pointer mt-5"
    >
      <div
        class="flex items-center gap-3 justify-around view-all-btn"
        (click)="handleAllClasses()"
      >
        <span class="body-1 medium">View All Classes</span>
        <mat-icon>keyboard_arrow_right</mat-icon>
      </div>
    </div>
  </section>
</section>

<section *ngIf="batchList.length === 0">
  <div
    class="no-batch w-full mt-1 md:mt-3 lg:mt-6"
    *ngIf="batchList.length === 0 && processing === false"
  >
    <span>No Classes Scheduled</span>
    <p>Enroll in our batches and start learning!</p>
    <button class="explore-btn" (click)="exploreBatches()">
      Explore Batches
    </button>
  </div>
</section>
