<ng-container *ngIf="processing">
  <div class="h-full w-full loading-screen flex items-center justify-center">
    <div class="loader"></div>
  </div>
</ng-container>

<iframe
  [hidden]="processing"
  allow="clipboard-read; clipboard-write; accelerometer; autoplay;fullscreen; encrypted-media; gyroscope; picture-in picture "
  #batchPlus
  id="batchPlus"
  class="responsive-iframe"
  (load)="checkLoaded($event, batchPlus)"
  [src]="url | safe : 'resourceUrl'"
></iframe>
