import { Injectable } from '@angular/core';
import { ORGANIZATION_ID } from '../../constants/app.constant';

const V3 = 'v3';
@Injectable({
  providedIn: 'root',
})
export class AppUrlService {
  constructor() {}

  public get SET_FCM_TOKEN() {
    return 'v1/' + 'devices';
  }

  public IMAGE_UPLOAD() {
    return 'v1/' + 'files';
  }
  public BASE64TOAUDIO() {
    return 'v1/' + `files/base64-to-audio`;
  }

  /**Prefernce service*/

  public GET_PREFERENCES() {
    return 'v1' + `/web-preference/${ORGANIZATION_ID}`;
  }

  public get GET_VIMEO_DOWNLOAD_URL() {
    return 'v1' + `/files/vimeo/getVideoQuality`;
  }

  public get PUBLIC_TICKET_ENQUIRY() {
    return 'v1' + `/enquiry/public-enquiry-ticket`;
  }

  public get GET_LICENCE() {
    return 'v1' + `/videos/get-otp`;
  }

  public get CREATE_DOUBT() {
    return 'v1' + '/doubts';
  }

  public get CHECK_IRRELEVANCY() {
    return 'v1/' + `doubts/check/irrelevancy`;
  }
  public get USER_BLOCKED() {
    return 'v1' + `/users/chat-block-status`;
  }

  /**
   * Wallet
   */
  public get WALLET_HISTORY() {
    return 'v1' + `/orders/wallet-history`;
  }

  public JOIN_CLASS(batchId: any, batchSubjectId: any, scheduleId: any) {
    return (
      'v1' +
      `/batches/${batchId}/subject/${batchSubjectId}/schedule/${scheduleId}/join-class`
    );
  }

  public GET_RATING_TAGS() {
    return 'v1' + `/ratings/rating-tags`;
  }

  public GET_TEACHER_RATING_TAGS() {
    return 'v3' + `/saarthi/feedback/rating-tags`;
  }

  public GET_MY_RATINGS_TEACHER_TAGS() {
    return 'v3' + `/saarthi/feedback`;
  }

  public GET_MY_RATINGS(typeId: string) {
    return 'v1/' + `ratings/${typeId}/my-ratings`;
  }

  GET_REPORTING_TAGS() {
    return 'v1' + `/organizations/${ORGANIZATION_ID}/video-report-tags`;
  }

  public GET_AWS_KEY() {
    return 'v1' + `/files/get-signed-cookie`;
  }

  public GET_ENCRYPTED_KEY() {
    return 'v3' + '/files/send-analytics-data';
  }
  /** juspay **/

  public JUSPAY_INIT_SIGNATURE() {
    return `v1/` + `orders/init-signature`;
  }

  public GET_ORDER_STATUS(orderId: string) {
    return 'v1/' + `orders/${orderId}/status`;
  }

  public GET_PAYMENT_METHOD(ORG: string) {
    return `v1/organizations/${ORG}/active-payment-source`;
  }

  /**Content APIS*/

  public GET_PROGRAM_DETAILS(programId: string) {
    return 'v1' + `/programs/${programId}`;
  }

  public GET_SUBJECTS(programSlug: string) {
    return 'v1' + `/programs/${programSlug}/subjects`;
  }

  public GET_COMMENTS(scheduleId: string) {
    return 'v1' + `/comments/${scheduleId}`;
  }

  public BATCH_COMMENT_REPORT(commentId: string) {
    return 'v1' + `/enquiry/${commentId}/report-comment`;
  }

  public CONTENT_COMMENT_ID(commentId: string) {
    return 'v1/' + `comments/${commentId}`;
  }

  public POST_RATING() {
    return 'v1' + `/ratings`;
  }

  public CONTENT_UPVOTE_TOGGLE() {
    return 'v1/' + `upvotes`;
  }

  public ADD_COMMENT() {
    return 'v1' + `/comments`;
  }

  public CONTENT_UPVOTE_COMMENT(commentId: string) {
    return 'v1' + `/comments/${commentId}/new-upvote`;
  }

  public CHAT_UNREAD(conversationId: string, sender: string) {
    return (
      'v1' + `/conversation/${conversationId}/chat/${sender}/unread-messages`
    );
  }

  public MY_RATINGS_BY_IDS() {
    return 'v1' + '/ratings/my-ratings-by-ids';
  }

  public USER_DETAILS_LIST() {
    return 'v1' + '/users/get-user-details-list';
  }

  /**Batch*/

  GET_TOPIC_CONTENTS(batchSlug: string, subjectSlug: string) {
    return `v2` + `/batches/${batchSlug}/subject/${subjectSlug}/contents`;
  }

  GET_DPP_QUIZ(batchSlug: string, subjectSlug: string) {
    return `v3` + `/test-service/tests/dpp`;
  }

  GET_CHECK_TEST() {
    return `v3` + `/test-service/tests/check-tests`;
  }

  GET_BG_DPP_LIST(
    batchId: string,
    startDate: string,
    endDate: string,
    subjectId: string,
    page: number,
    limit: number
  ) {
    return (
      `v3` +
      `/test-service/battleground/users/dpp-list/${batchId}?startDate=${startDate}&endDate=${endDate}&subjectId=${subjectId}&limit=${limit}&page=${page}`
    );
  }

  public CHECK_WATCH_LIMIT(
    batchId: string,
    subjectId: string,
    scheduleId: string
  ) {
    return (
      'v1' +
      `/batches/${batchId}/subject/${subjectId}/schedule/${scheduleId}/check-watch-limit`
    );
  }

  public UNENROLL_STUDENT(batchId: string) {
    return `v1/batches/${batchId}/unenroll-student`;
  }

  /**Doubts*/

  GET_ALL_SOLUTION_OF_DOUBT(doubtId: string) {
    return 'v1' + `/doubts/${doubtId}/get-all-solutions`;
  }

  GET_DOUBT_REPORT_TAGS() {
    return 'v1' + '/doubts/report-tags';
  }

  GET_ALL_DOUBTS(roomId: string, scheduleId: string) {
    return `v1` + `/batches/${roomId}/${scheduleId}/my-doubts`;
  }

  GET_SCHEDULE_SLIDES(batchId: string, subjectId: string, scheduleId: string) {
    return (
      'v1' +
      `/batches/${batchId}/subject/${subjectId}/schedule/${scheduleId}/slides`
    );
  }

  GET_COMMON_DOUBTS(scheduleId: string, slideId: string) {
    return 'v1' + `/doubts/${scheduleId}/${slideId}/get-common-doubts`;
  }

  GET_USER_DOUBTS(roomId: string, scheduleId: string) {
    return 'v1' + `/batches/${roomId}/${scheduleId}/my-doubts`;
  }

  public CREATE_SAME_DOUBT() {
    return 'v1' + '/doubts/addSameDoubt';
  }

  public GET_DOUBT_DETAILS(doubtId: string) {
    return 'v1' + `/doubts/${doubtId}/get-user-doubts`;
  }

  public CHAT(conversationId: string) {
    return 'v1' + `/conversation/${conversationId}/chat`;
  }

  public CHAT_RULE(conversationId: string) {
    return 'v1/' + `conversation/${conversationId}/room-info`;
  }

  public GET_POPULAR_DOUBTS_LIST(scheduleId: string) {
    return (
      'v1' + `/doubts/get-popular-doubts-for-video/scheduleId/${scheduleId}`
    );
  }

  public REPORT_DOUBT(doubtId: string) {
    return 'v1/' + `doubts/${doubtId}/report-doubt`;
  }

  public EDIT_DELETE_DOUBT(doubtId: string) {
    return 'v1' + `/doubts/${doubtId}`;
  }

  public GET_USERS_DOUBTS_ON_SLIDE(slideId: string) {
    return 'v1' + `/doubts/${slideId}/get-my-doubts`;
  }

  public SAVE_VIDEO() {
    return 'v3' + '/video-stats/save-stats';
  }

  public SAVE_PDF() {
    return 'v3' + '/stats/save-stats';
  }

  GET_DOUBT_CONNECT_LIST(roomId: string) {
    return 'v1' + `/doubt-connect/room/${roomId}/plans`;
  }

  ENROLL_IN_FREE_PLAN(roomId: string, planId: string) {
    return 'v1' + `/doubt-connect/room/${roomId}/plan/${planId}/enroll-student`;
  }

  START_DOUBT_SESSION(studentMappingId: string) {
    return (
      'v1' +
      `/doubt-connect/plan/studenMapping/${studentMappingId}/session-start`
    );
  }

  GET_MENTOR_LIST(roomId: string) {
    return 'v1' + `/doubt-connect/room/${roomId}/mentors`;
  }

  GET_DOUBT_CONNECT_ROOM() {
    return 'v1' + '/doubt-connect/room';
  }

  GET_USER_ACTIVE_PLAN(roomId: string) {
    return 'v1' + `/doubt-connect/room/${roomId}/get-active-plan`;
  }

  GET_DOUBT_CONNECT_META_DATA() {
    return 'v1' + '/doubt-connect/doubt-connect-metadata';
  }
  /**
   * Test details
   */
  public GET_TEST_DETAILS(testId: string) {
    return `v2` + `/tests/${testId}/test-details`;
  }
  public GET_TEST(testId: string) {
    return `v1` + `/tests/${testId}`;
  }

  /**
   *
   */
  public get PUBLIC_APPLICATION_DETAILS() {
    return 'v1' + `/organizations/get-organization-details/${ORGANIZATION_ID}`;
  }

  /**Notifications */
  public get GET_NOTIFICATION() {
    return 'v1/' + 'notification';
  }

  public get GET_NOTIFICATION_IN_APP() {
    return 'v1/' + 'notification/in-app-notification';
  }

  public get READ_NOTIFICATION() {
    return 'v1/' + 'notification/mark-read';
  }

  public GET_NOTIFICAION_METADATA(campaignId: string) {
    return 'v1/' + `notification/${campaignId}/campaign-meta-details`;
  }

  public SUBSCRIBE_TO_NOTIFICATION() {
    return `v1/` + `notification/subscribe-topic`;
  }

  /** Live class polling */
  public CREATE_POLL() {
    return `v2/` + `poll/create-poll`;
  }

  public STOP_POLL() {
    return `v2/` + `poll/close-poll`;
  }

  public FETCH_POLL(pollId: string) {
    return `v2/` + `poll/fetch-poll/poll-id/${pollId}`;
  }

  public FETCH_ACTIVE_POLL(entityId: string) {
    return `v2/` + `poll/entity/${entityId}/active-poll`;
  }

  public UPVOTE_POLL() {
    return `v2/` + `poll/upvote-poll`;
  }

  public FETCH_POLL_DETAIL(pollId: string) {
    return `v3/` + `poll/${pollId}/details`;
  }

  public GET_POLL_DETAILS(pollId: string) {
    return `v2/` + `poll/${pollId}/details`;
  }

  public GET_POLL_RESULT(pollId: string) {
    return `v2/` + `poll/${pollId}/result`;
  }
  /** Student side poll enhancement */

  // public CREATE_POLL() {
  //   return `v3/` + `poll/create-poll`;
  // }

  // public CLOSE_POLL() {
  //   return `v3/` + `poll/close-poll`;
  // }

  public DELETE_POLL() {
    return `v2/` + `poll/delete-poll`;
  }

  // public UPVOTE_POLL() {
  //   return `v3/` + `poll/upvote-poll`;
  // }

  // public FETCH_ACTIVE_POLL(entityId: string) {
  //   return `v3/` + `poll/entity/${entityId}/active-poll`;
  // }

  public POLL_RESULT(pollId: string) {
    return `v2/` + `poll/${pollId}/result`;
  }

  public GENERATE_RESULT() {
    return `v2/` + `poll/generate-result`;
  }

  public SHOW_USER_RESULT(pollId: string) {
    return `v2/` + `poll/${pollId}/show-user-result`;
  }
  /**  getting community details**/
  public GET_COMMUNITY_DETAILS(batchId: string) {
    return `v3/community/channels/batch/${batchId}`;
  }

  /** BATCH ALL DOUBTS **/
  /**Saarthi */
  public SAARTHI_DETAIL() {
    return `v3/` + `saarthi/details`;
  }

  // Saarthi Subjects
  public SAARTHI_SUBJECTS(saarthiId: string) {
    return `v3/` + `saarthi/${saarthiId}/subjects`;
  }

  // Saarthi Active Plans
  public SAARTHI_ACTIVE_PLANS(saarthiId: string) {
    return `v3/` + `saarthi/${saarthiId}/active-plan`;
  }

  public EXAMS_DATA() {
    return `v3/` + `saarthi/exams`;
  }

  public ENROLL_STUDENT(saarthiId: string, saarthiPlanId: string) {
    return `v3/` + `saarthi/${saarthiId}/plan/${saarthiPlanId}/enroll-student`;
  }

  public SOLUTION_FEEDBACK(saarthiId: string) {
    return `v1/` + `ratings/details/${saarthiId}`;
  }

  public SOLUTION_LIKE_COUNT() {
    return `v1/` + `ratings`;
  }

  public GET_PLANS(saarthiId: string) {
    return `v3/` + `saarthi/${saarthiId}/plans`;
    /** BATCH ALL DOUBTS **/
  }

  public GET_BATCHID_SUBJECTS(batchId: string) {
    return `v1/` + `batches/${batchId}/get-batch-subjects`;
  }

  public GET_SCHEDULES(batchId: string, subjectId: string) {
    return `v1/` + `doubts/batch/${batchId}/subject/${subjectId}/my-doubt`;
  }

  public GET_SOLUTION_TAGS() {
    return `v1/` + `doubts/doubt-solution-tags`;
  }

  public GET_STUDENT_PROGRESS(obj: any) {
    return `v3/saarthi/planner-goal/planner-progress?week_date=${obj.currentYear}-${obj.currentWeek}`;
  }

  public ADD_GOALS() {
    return `v3/saarthi/planner-goal/`;
  }

  public EDIT_GOALS(id: any) {
    return `v3/saarthi/planner-goal/${id}`;
  }

  public GET_STUDENT_PLANNER_GOALS(date: any) {
    return `v3/saarthi/planner-goal/goals?date=${date}`;
  }

  public DELETE_STUDENT_PLANNER_GOALS(id: any) {
    return `v3/saarthi/planner-goal/${id}`;
  }

  public GET_VIDEO_URL() {
    return `v3/` + `saarthi/intro-video`;
  }

  public COMPLETE_PLANNER_STUDENT(planner_id: any) {
    return `v3/saarthi/planner-goal/${planner_id}/complete-goal`;
  }

  public GET_STUDENT_MAPPING(saarthiId: string, subjectId: string) {
    return `v3/` + `saarthi/${saarthiId}/subject/${subjectId}/get-mapping`;
  }

  // Ask Concept
  public GET_SUGGESTIVE_CONTENT() {
    return `v3/` + `search/contents/suggestive`;
  }

  public GET_CONTENT_RESULT() {
    return `v3/` + `search/contents/results`;
  }

  public GET_CONTENT_DETAILS() {
    return `v3/` + `search/contents/details`;
  }

  // Ask Doubt

  public GET_DOUBT_IMAGE() {
    return `v3/` + `search/ask-doubt/doubt-image`;
  }

  // This function generates a URL path for retrieving an image related to asking a doubt.
  public GET_ASK_DOUBT_IMAGE() {
    return `v3/` + `search/ask-doubt/doubt-image-v2`;
  }
  public GET_SIGNED_URL() {
    return `v1/` + `files/signed-url`;
  }
  public GET_DOUBT_TEXT() {
    return `v3/` + `search/ask-doubt/doubt-text`;
  }

  public USER_PROFILE_URL(userId: string) {
    return `v1/` + `users/user-profile/${userId}`;
  }

  // get Exam Plan Modal Details

  public GET_MY_SAARTHI_PLANS() {
    return `v3/` + `saarthi/my-active-saarthi`;
  }

  // Live-chat teacher online or not
  public TEACHER_ONLINE_OR_NOT(saarthiId: string, teacherId: string) {
    return (
      `v3/` + `saarthi/hundred-ms/${saarthiId}/room/${teacherId}/live-check`
    );
  }

  //new sme api
  public GET_EXPERT_INFO(batchId: string, teacherId: string) {
    return `v1/` + `batches/${batchId}/${teacherId}/teacher-details`;
  }

  public POST_COMPLETE_DOUBT() {
    return `v1/` + `doubts/completeDoubt`;
  }

  // Refer and Earn New

  public VOUCHER_REDEMPTION_API() {
    return `v1` + `/benepik/redeem`;
  }

  public get GET_INVITED_REFERRAL_USER_LIST() {
    return 'v1/users/invited-coin-referral-uers-list';
  }

  // public CLIENT_BALANCE_API(){
  //   return `v1` + `/benepik/balance`;
  // }

  public AMOUNT_TRANSFER_UPI_BANK() {
    return `v1` + `/benepik/transfer`;
  }

  // public TRANSFER_STATUS_API(){
  //   return `v1` + `/benepik/transfer-status`;
  // }

  public UPI_VERIFY() {
    return `v1` + `/user-bank-details/upi-verify`;
  }

  public USER_BANK_DETAILS() {
    return `v1` + `/user-bank-details`;
  }
  public PRODUCT_COUPONS() {
    return `v1/` + `benepik/products`;
  }

  public REFEERED_TRANSACTION_HISTORY() {
    return `v1/` + `user-bank-details/transaction-details`;
  }

  public TRANFERED_STATUS() {
    return `v1/` + `benepik/transfer-status`;
  }

  public ORGANIZATION_STATUS() {
    return 'v1/' + 'organizations/preferences';
  }

  public GET_USER_BANK_DETAILS() {
    return 'v1/' + 'user-bank-details';
  }

  public EMAIL_VOUCHER() {
    return 'v1/' + 'benepik/send-voucher-mail';
  }

  public REFER_USER() {
    return 'v1/users/invite-coin-referral';
  }

  public TRANSFER_TO_WALLET() {
    return 'v1/users/transfer-to-wallet';
  }

  public APPLY_COUPONS() {
    return 'v1/orders/apply-coupons';
  }

  public VERIFY_REFER_CODE() {
    return `v1/` + `users/check-coin-refer-code`;
  }

  //Cohorts

  public GET_COHORT_LANDING_QUESTIONS() {
    return `v1/` + `cohort/landing-questions`;
  }

  public GET_COHORT_BATCHES(cohortId: string) {
    return `v1/` + `cohort/${cohortId}/batches`;
  }

  public GET_COHORT_CONFIG(cohortId: string) {
    return `v1/` + `cohort/${cohortId}`;
  }

  public UPDATE_USER_COHORT() {
    return `v1/` + `users/update-cohort`;
  }

  public GET_RECENT_COHORTS() {
    return `v1/` + `cohort/recent-cohorts`;
  }

  public UPDATE_RECENT_COHORTS() {
    return `v1/` + `users/update-recent-cohort`;
  }

  public GET_EXAM_PREFERENCES() {
    return `v1/` + `organizations/preferences`;
  }

  public NOTIFY_USER() {
    return `v3/` + `batches/user-notify`;
  }

  // unread conversation
  public GET_UNREAD_CONVERSATION(userId: string) {
    return `v3/` + `saarthi/student/${userId}/unread-conversations`;
  }

  public GET_ZOOM_MEET_URL() {
    return `v3/saarthi/live-kit/meet-url`;
  }
  public GET_TAGS() {
    return `v1/` + `organizations/${ORGANIZATION_ID}/report-tags`;
  }
  public UNREAD_CLEAR(conversationId: string) {
    return `v1/` + `conversation/${conversationId}/chat/reset-unread-count`;
  }

  //sme revamped
  public GET_DOUBT_COMMENTS(typeId: string) {
    return `v1/` + `comments/${typeId}/solution-comments`;
  }

  public POST_DOUBT_COMMENTS() {
    return `v1/` + `comments`;
  }

  public DELETE_DOUBT_COMMENTS(commentId: string) {
    return `v1/` + `comments/${commentId}/solution-comment`;
  }

  public UPDATE_DOUBT(doubtId: string) {
    return 'v1' + `/doubts/${doubtId}`;
  }

  public GET_STUDENT_DETAILS() {
    return `v1/` + `doubts/subject-matter-expert/feedback-overall`;
  }

  public SEND_SOLUTION() {
    return `v1/` + `doubts/send-solution`;
  }

  REPORT_DOUBT_COMMENT(commentId: string) {
    return `v1/` + `comments/${commentId}/report-comment`;
  }

  GET_SLIDE_DETAIL(slideId: string) {
    return (
      `v1/` + `doubts/subject-matter-expert/slide/${slideId}/get-slide-detail`
    );
  }

  GET_SUBJECT_WISE_DOUBTS(batchId: string, subjectId: string) {
    return (
      `v1/` +
      `doubts/batch/${batchId}/subject/${subjectId}/get-chapter-wise-doubt`
    );
  }

  GET_SCHEDULE_WISE_DOUBTS(
    batchId: string,
    subjectId: string,
    chapterId: string
  ) {
    return (
      `v1/` +
      `doubts/batch/${batchId}/subject/${subjectId}/chapter/${chapterId}/get-schedule-wise-doubt`
    );
  }

  GET_SLIDE_WISE_DOUBTS(
    batchId: string,
    subjectId: string,
    chapterId: string,
    scheduleId: string
  ) {
    return (
      `v1/` +
      `doubts/batch/${batchId}/subject/${subjectId}/chapter/${chapterId}/schedule/${scheduleId}/get-slide-wise-doubt`
    );
  }
  public SLOTS_DATA(classes: any, exam: any) {
    return (
      `v3/` +
      `saarthi/get-slots?class=${classes.replace('+', '%2B')}&exam=${exam}`
    );
  }

  public EXCHANGE_TOKEN() {
    return V3 + '/oauth/exchange-token';
  }

  public BATCH_FORM_SUBMIT() {
    return `v1/` + `form-data`;
  }
  public CENTER_DETAILS(centerId: string) {
    return `v1/` + `pathshala/center/${centerId}/details`;
  }

  //MAHAPACK

  public MAHAPACK_MAPPING() {
    return 'v3/' + 'batch-service/mahapack-mapping';
  }

  public BATCH_CONFIG(batchId: string) {
    return 'v3/' + `batch-service/batch-config/${batchId}`;
  }

  public TESTIMONIAL(batchId: string) {
    return 'v1/' + `batches/${batchId}/testimonials`;
  }

  public MAHAPACK_DEMO_VIDEO() {
    return 'v1/' + `demo-videos`;
  }

  public GET_FORM_LIST_URL() {
    return 'v1/' + 'form/listV2';
  }

  public POST_SUBMIT_FORM(id: string) {
    return 'v1/' + `form/submitV2/${id}`;
  }

  public GET_THANKYOU_DETAIL(formId: string) {
    return 'v1/' + `form/form-landing-page-config/${formId}`;
  }

  public GET_USER_SEGMENT() {
    return 'v3/' + 'users';
  }
  public GET_PLANNER_SUBJECTS(cohortId: string) {
    return 'v1/' + `cohort/${cohortId}/subjects`;
  }

  public GET_PACKAGE_LIST() {
    return 'v3/' + 'packages';
  }

  public GET_COUPON_LIST() {
    return 'v3/' + 'order-management-service/coupons/user-coupons';
  }

  public GET_ADDONS_LIST(batchId: string) {
    return 'v1/' + `batches/${batchId}/addons`;
  }

  public CREATE_FBT_FEE() {
    return 'v3/' + 'packages/create-fbt-fee';
  }
  public GET_BATCHES_LIST_DASHBOARD() {
    return 'v3/' + 'batch-service/batches';
  }
  public GET_TEST_LIST_DASHBOARD() {
    return `v3/` + `test-service/test-categories/my-test-categories`;
  }

  public GET_DPP_COMMENTS_LIST(typeId: string) {
    return 'v1' + `/comments/${typeId}`;
  }

  public ADD_DPP_COMMENTS() {
    return 'v1' + `/comments`;
  }

  public REPORT_DPP_COMMENTS(commentId: string) {
    return 'v1' + `/comments/${commentId}/report-comment`;
  }

  public DELETE_DPP_COMMENTS(commentId: string) {
    return 'v1' + `/comments/${commentId}/solution-comment`;
  }
  public BUY_NOW(batchId: string) {
    return 'v3/' + `batches/${batchId}/buy-now`;
  }
  public BUSY_CHECK() {
    return `v3/` + `live-kit/zoom/busy-check`;
  }
  public FIND_OTHER_COACH() {
    return `v3/` + `live-kit/zoom/other-coach`;
  }
  public NOTIFICATION_API() {
    return `v3/` + `live-kit/zoom/push-notification`;
  }

  public VIDYAPEETH_LEAD_EVENT() {
    return 'v3/' + 'vidyapeeth-lead-events';
  }

  public GET_MENTORSHIP_BANNERS(batchId: string) {
    return 'v1/' + `batches/${batchId}/banners`;
  }

  public VERIFY_AUTH_TOKEN() {
    return 'v3/' + `oauth/verify-token`;
  }
  // get zoom meeting link
  public GET_ZOOM_MEETING_URL() {
    return 'v3/' + 'meeting/schedule/participant';
  }

  public GET_SELF_LEARNING_PLANS(batchId: string) {
    return 'v1/' + `batches/${batchId}/plans`;
  }
  /* Update Number */
  public GET_UPDATE_NUMBER_CONFIG() {
    return 'v1/' + 'users/is-eligible';
  }

  public UPDATE_NUMBER_SEND_OTP() {
    return 'v1/' + 'users/phone/otp';
  }

  public UPDATE_NUMBER_VERIFY_OTP() {
    return 'v1/' + 'users/phone/verify';
  }

  /* Admission Journey */
  public GET_PATHSHALA_DETAILS(batchSlug: string) {
    return `v1/` + `batches/${batchSlug}/pathshala-details`;
  }

  /* Third Party Services */
  public FETCH_DATES() {
    return (
      `thirdPartyService/public-api/scheme-data/v1/fetch/` + `programdates`
    );
  }

  public FETCH_FEE_INSTALLMENT() {
    return (
      `thirdPartyService/public-api/scheme-data/v1/fetch/` +
      `fee-and-installments`
    );
  }

  public GET_SCHOLARSHIP(cohortName: string, className: string) {
    return `vpservice/api/rank-scholarship/v1/search/scholarship?cohort=${cohortName}&className=${encodeURIComponent(
      className
    )}`;
  }

  public USER_SCHEME_DATA() {
    return `thirdPartyService/api/scheme-data/v2/` + `fetch/userscheme`;
  }

  public GET_ADMISSION_FORM(orderId: string) {
    return (
      `thirdPartyService/api/file-urls/v1/fetch/` +
      `admissionform?orderId=${orderId}`
    );
  }

  public GET_ORDER_RECIEPT(orderId: string) {
    return (
      `thirdPartyService/api/file-urls/v1/fetch/` +
      `orderreceipt?orderId=${orderId}`
    );
  }

  public GET_ORDER_DATA(orderId: string) {
    return 'v2/' + `orders/${orderId}/order-detail`;
  }

  public GET_ORDER_INVOICE(orderId: string) {
    return 'v2/' + `orders/order-invoice/${orderId}`;
  }

  public GET_PROGRAMDATES_PROGRAMDURATION() {
    return `thirdPartyService/public-api/scheme-data/v1/fetch/programdata`;
  }

  public GET_FEEDETAILS_FEEINSTALLMENT() {
    return `thirdPartyService/public-api/scheme-data/v2/fetch/fee-and-installments`;
  }
  public SAT_ENTRY_POINT(examName: string) {
    return `vpservice/general-api/isat-landing-page/v2?examName=${examName}`;
  }

  public GET_TEST_FEEDBACK_RATING_TAGS() {
    return 'v3/' + 'test-service/test-feedback/rating-tags';
  }

  public GET_TEST_FEEDBACK() {
    return 'v3/' + 'test-service/test-feedback';
  }

  public GET_DOUBT_SOLUTIONS_CHAT(conversationId: string) {
    return 'v1/' + `conversation/${conversationId}/chat/send-solutions`;
  }

  public GET_FREE_CONTENT(batchId: string) {
    return 'v1/' + `batches/${batchId}/free-schedule`;
  }
  public GET_COMPARE_PLANS(batchId: string) {
    return `batch-service/v2/batch-plans/${batchId}/compare-plans`;
  }

  public GET_BATCH_PLUS_BANNER(batchId: string) {
    return `batch-service/v4/batch-config/${batchId}/plan-banner`;
  }

  public GET_BATCH_UPGRADE_BANNER(batchId: string) {
    return `batch-service/v1/batch-config/${batchId}/upgrade-banner`;
  }

  public SNOOZE_BATCH_PLAN_UPGRADE(batchId: string) {
    return `batch-service/v4/batch-config/${batchId}/plan-banner/snooze`;
  }

  public SNOOZE_BATCH_UPGRADE_POP_UP_SNOOZE(batchId: string) {
    return `batch-service/v1/batch-config/${batchId}/upgrade-banner/snooze`;
  }

  public DELETE_MESSAGE(conversation: string, chat: string) {
    return `v1/conversation/${conversation}/chat/${chat}`;
  }

  public GET_TEST_PRESS_REDIRECTION_URL() {
    return `v3/` + `test-service/test-categories/testpress/redirect-url`;
  }
  public GET_ACTIVE_SLOTS(webinarId: string) {
    return `batch-service/batches/${webinarId}/active-slots`;
  }
  public GET_BOOKED_SLOTS(webinarId: string) {
    return `batch-service/batches/${webinarId}/booked-slots`;
  }

  public GET_ACTIVE_DATE_SLOTS(webinarId: string) {
    return `batch-service/batches/${webinarId}/schedules-for-given-date`;
  }

  public GET_SCHEDULE_SLOT_DATES(webinarId: string) {
    return `batch-service/batches/${webinarId}/schedule-dates`;
  }

  public GET_ACTIVE_TABS(batchId: string) {
    return `v1/batches/${batchId}/active-tabs`;
  }
  public GET_STUDENT_SESSION_LIST(conversationId: string) {
    return `v3/saarthi/chat-session/${conversationId}/detail`;
  }
  public MARK_SESSION(sessionId: string) {
    return `v3/saarthi/chat-session/update-status/${sessionId}`;
  }
  public GET_ALL_SESSIONS(conversationId: string) {
    return `v3/saarthi/chat-session/${conversationId}/summary`;
  }
  public MARK_ALL_SESSION() {
    return `v3/saarthi/chat-session/update-status/all-resolved`;
  }
  public GET_SESSSION_COUNT(conversationId: string) {
    return `v3/saarthi/chat-session/${conversationId}/counts`;
  }

  public GET_PREMIUM_BATCHES(cohortId: string) {
    return `batch-service/batches/v1/cohort/${cohortId}/premium/master`;
  }

  // batch clubbing
  public GET_BATCH_CLUBBING_OFFERINGS(tabId: string) {
    return `batch-service/v1/offerings/${tabId}`;
  }

  public GET_BATCH_CLUBBING_OFFERINGS_DATA(batchId: string) {
    return `batch-service/v1/batch-plans/${batchId}/offering-data`;
  }

  // part payment

  public GET_BATCH_PLANS(batchId: string) {
    return `batch-service/v1/batch-plans/batch/${batchId}/plans`;
  }

  public GET_EMI_INVOICE(orderId: string) {
    return `v3/order-management-service/invoice/${orderId}/emi-invoice`;
  }

  public GET_ALL_INSTALMENTS(batchId: string) {
    return `batch-service/v1/fee/batch/${batchId}/all-instalments`;
  }

  public GET_UPCOMING_INSTALMENT(batchId: string) {
    return `batch-service/v1/fee/batch/${batchId}/upcoming-instalment`;
  }

  public GET_UPCOMING_INSTALMENTS() {
    return `batch-service/v1/fee/upcoming-instalments`;
  }

  public GET_EMI_INFO() {
    return `v3/order-management-service/orders/emi-info`;
  }

  public GET_EZ_PAY_SDK_URL() {
    return `pay-service/secure/v1/payments/generate-url`;
  }

  public GET_USER_PROFILE_INFO() {
    return 'v1/' + 'users/user-profile-info';
  }

  public GET_BATCH_STATUS() {
    return `v3/users`;
  }

  public UNLEASH_FEATURE_FLAG() {
    return `v3/unleash/check-feature`;
  }

  public STREAK_INFO_URL() {
    return `engagement/streak/info`;
  }
  public SHOW_STREAK_UNLEASH_INFO_URL() {
    return `engagement/streak/showStreak`;
  }

  public REAL_TEST_LOGIN() {
    return `v3/test-service/test-categories/real-test/login`;
  }

  public TEST_NPF_EXPLORE() {
    return `/v3/test-service/tests/explore-event`;
  }

  public BATCH_CONFIG_LANGUAGES(batchId: string) {
    return `v3/batch-service/batch-config/${batchId}/languages`;
  }

  public REFER_AND_EARN(cohortId: string) {
    return `v1/student-acquisition/ambassador/commission-structure/${cohortId}/refer-and-earn`;
  }
  public COURSE_LOGIN() {
    return `/v2/auth/sso/login`;
  }

  public UPDATE_TAXATION_STATE(orderId: string) {
    return `/v3/order-management-service/orders/${orderId}/taxation-state/update`;
  }
}
