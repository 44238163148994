import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ApplicationTypes } from '../../services/batch/batch.service';
import { AuthService } from '../../services/auth/auth.service';
import { GlobalObjectService } from '../../services/global-object/global-object.service';
import { FILTER_CLOSE } from 'src/app/core/assets.location';
import { StorageService } from '../../services/storage/storage.service';
import { GlobalService } from '../../services/global/global.service';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';
import {
  FORM_ACTION,
  FORM_SUBMITTED_SUCESSFULLY,
  FORM_VISIBLE,
} from 'src/app/core/analytics-events';

@Component({
  selector: 'app-lead-forms',
  templateUrl: './lead-forms.component.html',
  styleUrls: ['./lead-forms.component.scss'],
})
export class LeadFormsComponent implements OnInit {
  url: string = '';
  iframe: any;
  filterCloseIcon = FILTER_CLOSE;
  constructor(
    public dialogRef: MatDialogRef<LeadFormsComponent>,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    private authService: AuthService,
    private globalObjectService: GlobalObjectService,
    private storageService: StorageService,
    private globalService: GlobalService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {
    this.url = `${environment.LEAD_FORMS_LINK}?type=LEAD_FILTRATION&formId=${modalData?.formId}&batchId=${modalData?.batchId}`;
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
    this.sendData(iframe);
  }

  sendData(iframe: HTMLIFrameElement) {
    setTimeout(() => {
      const data = {
        applicationTypes: ApplicationTypes.LEAD_FORM,
        authToken: this.authService.getTokenAsValue(),
      };
      iframe?.contentWindow?.postMessage(data, '*');
    }, 500);
  }

  ngOnInit(): void {
    this.globalObjectService.windowObj.addEventListener(
      'message',
      this.eventHandler
    );
    this.formVisibleEvent();
  }

  closeLeadForm() {
    this.modalData.onCloseCB();
    this.dialogRef.close();
    this.actionEvent('close');
  }

  ngOnDestroy() {
    this.globalObjectService.windowObj.removeEventListener(
      'message',
      this.eventHandler
    );
  }

  eventHandler = (event: MessageEvent) => {
    if (
      event.data.key === 'triggerOnSuccess' &&
      event.data.applicationType === ApplicationTypes.LEAD_FORM
    ) {
      this.dialogRef.close();
      this.modalData.onSuccessCB();
      this.submitSucessfullEvent();
    }

    if (
      event.data.key === 'submit_click' &&
      event.data.applicationType === ApplicationTypes.LEAD_FORM
    ) {
      this.actionEvent('submit');
    }
  };

  actionEvent(source: 'submit' | 'close') {
    const data = {
      batch_id: this.modalData?.batchId,
      cohort_id: this.storageService.getValue('COHORT_ID', 'string') ?? '',
      user_type: this.globalService.getUserSegmentFromStorage() ?? '',
      exam: this.modalData?.exam,
      form_type: 'lead_gen_form',
      action_type: source === 'submit' ? 'submit_form_click' : 'close_form',
    };
    this.firebaseAnalytics.logEvents(
      FORM_ACTION,
      data,
      false,
      true,
      false,
      false,
      false
    );
  }

  formVisibleEvent() {
    const data = {
      batch_id: this.modalData?.batchId,
      cohort_id: this.storageService.getValue('COHORT_ID', 'string') ?? '',
      user_type: this.globalService.getUserSegmentFromStorage() ?? '',
      exam: this.modalData?.exam,
      form_type: 'lead_gen_form',
    };
    this.firebaseAnalytics.logEvents(
      FORM_VISIBLE,
      data,
      false,
      true,
      false,
      false,
      false
    );
  }

  submitSucessfullEvent() {
    const data = {
      batch_id: this.modalData?.batchId,
      cohort_id: this.storageService.getValue('COHORT_ID', 'string') ?? '',
      user_type: this.globalService.getUserSegmentFromStorage() ?? '',
      exam: this.modalData?.exam,
      form_type: 'lead_gen_form',
      batch_name: this.modalData?.batchName,
    };
    this.firebaseAnalytics.logEvents(
      FORM_SUBMITTED_SUCESSFULLY,
      data,
      false,
      true,
      false,
      false,
      false
    );
  }
}
