<mat-toolbar class="in-page-toolbar" *ngIf="!hideBackButton">
  <div>
    <button
      (click)="goToDetails()"
      class="bg-white hover:bg-gray-100 text-gray-800 font-semibold rounded-full"
      mat-button
    >
      <mat-icon>arrow_back_ios</mat-icon>
      Back
    </button>
  </div>
</mat-toolbar>

<app-layout>
  <div class="container mt-9 px-4 md:px-0">
    <div class="flex justify-between items-center">
      <div class="flex items-center justify-center">
        <img class="calender-logo" src="assets/svg/my_planner.svg" />
        <span class="planner-head heading-2 pl-3">My Planner</span>
      </div>
      <div>
        <div
          (click)="howPlannerWorkVideo()"
          class="planner-work-btn body-1 semibold pw-dark-blue cursor-pointer placeholder-indigo-500 whitespace-nowrap"
        >
          How Planner Works
        </div>
      </div>
    </div>

    <div
      *ngIf="!hideBatches"
      class="flex items-center justify-evenly w-full md:w-7/12 tab-div py-3 px-2 my-8 md:max-w-xl"
    >
      <span
        (click)="changeSection(tab)"
        *ngFor="let tab of tabs; let i = index"
        [ngClass]="{ 'active-tab': activeTab === tab, 'mx-2': i === 1 }"
        class="tab flex-1 body-1 bold-2 cursor-pointer text-center py-3 active-tab"
        [id]="'tab-' + i"
      >
        {{ tab }}
      </span>
    </div>
    <app-progress [goalProgress]="goalProgress"></app-progress>
    <app-week-calender [week]="currentWeek"></app-week-calender>
    <!-- <app-subject-name></app-subject-name> -->
    <div class="goal-container">
      <app-goal-list></app-goal-list>
    </div>
    <div class="add-btn-box">
      <button
        (click)="addNewGoal()"
        class="add-btn px-12 py-4"
        id="add-goal_btn"
      >
        + Add New Goal
      </button>
    </div>
  </div>
  <gyan-guru-widget></gyan-guru-widget>
</app-layout>
