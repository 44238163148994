import { Component, Input } from '@angular/core';
import {
  Component as TileComponent,
  Placeholder,
  StudyService,
} from 'src/app/common/services/study/study.service';
import { StorageService } from '../../services/storage/storage.service';
import { GlobalService } from '../../services/global/global.service';
import { FirebaseAnalyticsService } from '../../services/firebase-analytics/firebase-analytics.service';
import { STUDY_PAGE_EVENT, WIDGET_CLICK } from 'src/app/core/analytics-events';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { MatDialog } from '@angular/material/dialog';
import { PurchaseAlertComponent } from '../purchase-alert/purchase-alert.component';
import { BatchService } from '../../services/batch/batch.service';
import { lastValueFrom } from 'rxjs';
import { FORM_TRIGGERS } from 'src/app/constants/form.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-quick-access',
  templateUrl: './quick-access.component.html',
  styleUrls: ['./quick-access.component.scss'],
})
export class QuickAccessComponent {
  @Input() title: string;
  @Input() subTitle: string;
  @Input() widget_type: string;
  @Input() widgetId: string;
  @Input() isNew?: boolean;
  @Input() isBookmarkEnabled?: boolean = false;
  @Input() items: TileComponent[];
  @Input() cameFrom: string;
  @Input() screen?: string;
  @Input('batchData') batchData: BatchDetailModel;
  public mentorShipRole: string;

  constructor(
    private batchService: BatchService,
    private studyService: StudyService,
    private firebaseService: FirebaseAnalyticsService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private router: Router
  ) {
    this.batchData = this.storageService.getValue('BATCH_DATA', 'json') || '';

    if (this?.batchData?.enableMentorship) {
      this.getUserGroupRole();
    }
  }

  openPurchaseDialog() {
    const dialogRef = this.dialog.open(PurchaseAlertComponent, {
      data: {
        batchId: this.batchData.slug,
      },
      height: '160px',
      width: '450px',
      panelClass: 'purchase-alert',
    });
  }

  ngOnInit(): void {
    console.log({ items: this.items });
  }

  getShowTile(item: TileComponent) {
    const isMentorship = item.redirectUrl.includes('mentor-ship');
    const isBookmark = item.redirectUrl.includes('practice/bookmark');

    if (isMentorship) {
      return this.mentorShipRole !== 'Mentor';
    } else if (isBookmark) {
      return this.isBookmarkEnabled;
    }
    return true;
  }

  async getUserGroupRole() {
    try {
      if (this?.batchData?._id && this?.batchService) {
        const res = await lastValueFrom(
          this.batchService.getUserGroupRole(this?.batchData?._id || '')
        );
        if (res?.role) {
          this.mentorShipRole = res?.role;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  handleRedirection(url: string) {
    this.globalService.triggerAdmitCard(FORM_TRIGGERS.MY_BATCHES);
    if (url.includes('/practice/bookmark')) {
      localStorage.removeItem('REDUX_STATE');
      this.logBookmarkEvent();
      this.globalService.setBookmarkBackUrl('/study' + this.router.url);
      window.location.href = window.origin + '/practice/bookmarks';
      return;
    } else if (url.includes('my-doubts')) this.logDoubtClickEvent();
    if (url.includes('mentor-ship')) {
      if (this.batchData && !this?.batchData?.enableMentorship) {
        return;
      }
      if (
        this?.batchData?.enableMentorship &&
        !this?.batchData?.isPurchased &&
        this?.batchData?.fee?.amount !== 0 &&
        !this.batchData?.isBatchPlusEnabled
      ) {
        this.openPurchaseDialog();
        return;
      }
      const encodedName = encodeURIComponent(
        this?.batchData?.name?.replace(/\(/g, '%28').replace(/\)/g, '%29') || ''
      );
      url = `batches/${this?.batchData?.slug}/batch-overview/mentor-ship/{"_id":"${this?.batchData?._id}","name":"${encodedName}"}`;
    }
    this.logStudyEvent(url);
    this.studyService.redirectTo(url);
  }

  logStudyEvent(url: string): void {
    const data = {
      widgetid: this.widgetId,
      widget_type: this.widget_type,
      widget_redirection: url,
      batch_id: this.batchData._id || '',
      placeholder:
        this.cameFrom === Placeholder.ALL_CLASSES
          ? Placeholder.ALL_CLASSES
          : Placeholder.STUDY_PAGE,
    };
    this.firebaseService.logEvents(
      WIDGET_CLICK,
      data,
      false,
      true,
      true,
      true,
      true
    );
  }

  logBookmarkEvent() {
    this.firebaseService.logEvents(
      'my_bookmarks',
      { source: 'study page' },
      true,
      true,
      true,
      true,
      true
    );
  }

  logDoubtClickEvent() {
    const _selectedBatch = this.storageService.getScheduleClassBatch();

    this.firebaseService.logEvents(
      STUDY_PAGE_EVENT.DOUBT_CLICK,
      { batch_selected: _selectedBatch?.batch?.name || '' },
      true,
      true,
      true,
      true
    );
  }
}
