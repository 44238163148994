<section class="widget-wrapper py-8" *ngIf="items && items.length > 0">
  <div class="flex items-center gap-5">
    <span [innerHtml]="title | truncateText : 242 | safe : 'html'"></span>

    <span *ngIf="isNew" class="new-tag caption-2 semibold">New</span>
  </div>

  <div class="mt-2">
    <span
      [innerHtml]="subTitle | safe : 'html'"
      *ngIf="subTitle && subTitle.length > 0"
    ></span>
  </div>

  <div class="mt-5 grid grid-cols-12 gap-3 md:gap-5">
    <div
      *ngFor="let item of items"
      class="col-span-12 sm:col-span-6 md:col-span-4"
    >
      <div *ngIf="item.type === 'Dashboard'; else quickAccess">
        <app-dashboard variant="allclasses" [item]="item"></app-dashboard>
      </div>

      <ng-template #quickAccess>
        <app-quick-access-tile
          *ngIf="getShowTile(item)"
          (click)="handleRedirection(item.redirectUrl)"
          [title]="item.title"
          [subTitle]="item.subTitle"
          [image]="item.image.baseUrl + item.image.key"
          [isBattleGroundWidget]="item.redirectUrl.includes('battle-ground')"
          [cameFrom]="screen"
          [isNew]="isNew || item?.config?.isNew"
        ></app-quick-access-tile>
      </ng-template>
    </div>
  </div>
</section>
