import {
  BATCH_DISCOVERABILITY_EVENTS,
  BATCH_PLUS_EVENT,
} from '../../../core/analytics-events';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import {
  STORAGE_ENUM,
  StorageService,
} from './../../../common/services/storage/storage.service';
import {
  BATCH_ENUMS,
  BatchService,
} from 'src/app/common/services/batch/batch.service';
import { ShowErrorService } from './../../../common/services/showError/show-error.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subscription, fromEvent, lastValueFrom } from 'rxjs';
import {
  BatchPlan,
  BatchPlus,
  Offerings,
} from 'src/app/common/services/batch/batch.modal';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BatchDetailModel } from '../batch-overview/batch-overview.model';
import { environment } from 'src/environments/environment';
import { GlobalObjectService } from 'src/app/common/services/global-object/global-object.service';
import {
  ApplicationTypes,
  IFrameActions,
} from 'src/app/common/services/center-page.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaymentService } from 'src/app/common/services/payment/payment.service';
import { GlobalService } from 'src/app/common/services/global/global.service';
import { Base64 } from 'src/app/common/utils/encode-utilities';
import { CohortService } from 'src/app/common/services/cohort/cohort.service';
import { BATCH_CLUBBING_ENUM } from 'src/app/common/services/batch/batch.utils';

type T = keyof typeof Offerings;
@Component({
  selector: 'app-batch-plus-modal',
  templateUrl: './batch-plus-modal.component.html',
  styleUrls: ['./batch-plus-modal.component.scss'],
})
export class BatchPlusModalComponent implements OnInit, AfterViewInit {
  planData = new BatchPlus({});
  selectedPlanId: string = '';
  batchData = new BatchDetailModel({});
  processing: boolean = true;
  source: string;
  iframe: HTMLIFrameElement;
  url = `${window.location.origin}/batches-new`;
  // url = 'https://payment-pre-prod.pw.live';
  messageSub: Subscription;
  cameFrom: string;
  openFromBatchListing: boolean = false;
  iframeLoaded = false;
  @ViewChild('batchPlus', { read: ElementRef })
  batchPlus!: ElementRef<any>;
  userType: String = '';
  selectHighestPlan = false;
  routeToNewFbt = false;
  lockedPopup: boolean;
  userSegment: string = '';
  componentIframe: HTMLIFrameElement | null;
  constructor(
    private showErrorService: ShowErrorService,
    private batchService: BatchService,
    private dialog: MatDialogRef<BatchPlusModalComponent>,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalObjectService: GlobalObjectService,
    private paymentService: PaymentService,
    private storageService: StorageService,
    private globalService: GlobalService,
    private firebaseService: FirebaseAnalyticsService,
    private cohortService: CohortService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      batchData: BatchDetailModel;
      source: string;
      openFromBatchListing: boolean;
      source_cta?: string;
      planId?: string;
      selectHighestPlan?: boolean;
      isLockedPopup?: boolean;
      popUpCtaName?: string;
      iframe?: HTMLIFrameElement | null;
    }
  ) {
    this.cameFrom = data.source_cta || '';
    this.openFromBatchListing = data.openFromBatchListing;
    this.batchData = new BatchDetailModel(data.batchData);
    // this.batchService.setBatchData(this.batchData);
    this.source = data.source;
    this.selectedPlanId = data?.planId!;
    this.selectHighestPlan = data?.selectHighestPlan ?? false;
    this.url = this.url + `/batches/${data?.batchData?._id}/comparison-table`;
    this.lockedPopup = data?.isLockedPopup ?? false;
    this.componentIframe = data?.iframe ?? null;

    this.userType =
      this.globalService.getIsPathShalaFromStorage() === 'false'
        ? 'online'
        : 'offline';
    this.userSegment =
      this.globalService.getUserSegmentFromStorage()?.toLowerCase() || '';
  }

  updateURL() {
    const payload = {
      planId: this.selectedPlanId,
      batchName: this.batchData?.name,
      batchId: this.batchData?._id,
      cameFrom: this.cameFrom,
      openFromBatchListing: this.openFromBatchListing,
      selectHighestPlan: this.selectHighestPlan,
      shouldComparisonTableClose: true,
      pageName: this.source,
    };
    const encodedPayload = Base64.encode(JSON.stringify(payload));
    this.url = this.url + `?config=${encodedPayload}`;
  }

  async ngOnInit() {
    this.routeToNewFbt = this.paymentService._shouldRunNewFBT$.getValue();
    this.updateURL();

    setTimeout(() => {
      if (this.processing) {
        this.processing = false;
      }
    }, 3000);
  }

  displayPopCTAName(source: string) {
    switch (source) {
      case Source.BATCH_LISTING:
        return this.cameFrom === 'explore_cta' ? 'explore' : 'buy_now';
      case Source.COMPARE_PLANS_CTA:
        return 'compare_plans';
      case Source.FBT:
        return 'switch_plan';
      case Source.UPGRADE_BANNER:
        return 'level_up_to infinity';
      case Source.BATCH_CLUBBING:
        return 'buy_now';
      case Source.BATCH_DESCRIPTION_PAGE:
        return 'explore';
      case Source.ALL_CLASSES:
        return this.cameFrom === 'levelup_plus_cta' ? 'level_up' : this.source;
      case Source.TEST_SERIES_PAGE:
      case Source.INFINITE_PRACTICE:
      case Source.INFINITE_MENTORSHIP:
      case Source.SAARTHI:
      case Source.KHAZANA_PAGE:
      case Source.ALL_CLASSES_SUBJECTS:
      case Source.TEST_SERIES_ACCESS_PAGE:
      case BATCH_CLUBBING_ENUM.BATCH_CLUBBING:
        return this.cameFrom === 'locked_offering'
          ? 'locked_offering'
          : 'unlock';

      default:
        return source;
    }
  }

  getSourceName() {
    switch (this.source) {
      case Source.BATCH_LISTING:
        return 'batch_listing_page';

      case Source.FBT:
        return 'fbt';

      case Source.COMPARE_PLANS_CTA:
        return 'description_page';

      case Source.KHAZANA_PAGE:
        return 'khazana_page';
      case Source.UPGRADE_BANNER:
        return 'all_classes';

      case Source.INFINITE_PRACTICE:
      case Source.INFINITE_MENTORSHIP:
      case Source.SAARTHI:
      case Source.ALL_CLASSES:
      case Source.ALL_CLASSES_SUBJECTS:
      case Source.TEST_SERIES_PAGE:
        return this.source;
      default:
        return this.source;
    }
  }

  //firebase event
  logEvent(eventName: string, actionType?: string) {
    let source: string = this.getSourceName();
    const isLockedPopup = this.lockedPopup ? 'yes' : 'no';
    const defaultPlan = this.planData?.plans?.find(
      (plan) => plan?.isRecommended
    );

    const currentPlan = this.planData?.plans?.find(
      (plan) => plan?.isPlanPurchased
    );

    const selectedPlan = this.planData?.plans.find(
      (plan) => plan?._id === this.selectedPlanId
    );

    const isAnyPlanPurchased = this.planData?.plans?.some(
      (plan) => plan?.isPlanPurchased
    );

    const data = {
      page_name: source,
      user_type: this.userType,
      batch_id: this.batchData?._id,
      batch_name: this.batchData?.name,
      current_plan: currentPlan?.title || '',
      default_plan: defaultPlan ? defaultPlan?.title : '',
      popup_cta_name:
        this.source === 'STUDY_PAGE'
          ? 'level_up'
          : this.displayPopCTAName(this.source),
      cta_name: isAnyPlanPurchased ? 'upgrade_now' : 'buy_now',
      cohort_id: this.cohortService.getCohortIdFromLocal(),
      user_segment:
        this.userSegment === 'nobatch' ? 'no_batch' : this.userSegment,
      ...(eventName === BATCH_PLUS_EVENT.BUY_NOW_CLICKED && {
        is_plus_compare_popup: 'yes',
        is_plus_enabled: 'yes',
        exam: this.batchData?.exam || '',
        class: this.batchData?.class || '',
        boards: this.batchData?.board || '',
        plan_selected: selectedPlan ? selectedPlan?.title : defaultPlan?.title,
      }),
      ...(eventName === BATCH_PLUS_EVENT.PLUS_COMPARE_POPUP && {
        is_plus_enabled: 'yes',
      }),
      ...(eventName === BATCH_PLUS_EVENT.PLUS_POPUP_ACTION && {
        action_type: actionType,
        plan_selected: selectedPlan ? selectedPlan?.title : defaultPlan?.title,
      }),
      ...(eventName === BATCH_PLUS_EVENT.KNOW_MORE_CLICK && {
        inside_popup: 'yes',
      }),
    };

    this.firebaseService.logEvents(
      eventName,
      data,
      false,
      true,
      true,
      true,
      false
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.listenToMessages();
    }, 500);
  }

  ngOnDestroy() {
    this.batchData = new BatchDetailModel({});
    this.messageSub?.unsubscribe();
  }

  listenToMessages() {
    this.messageSub = fromEvent(
      this.globalObjectService.window!,
      'message'
    ).subscribe((event: any) => {
      if (!this.batchData?.slug) return;

      if (
        event.data.action === IFrameActions.PARENT_NAVIAGTION &&
        event.data.applicationType === ApplicationTypes.BATCH_PLUS_TABLE
      ) {
        if (event.data.redirectionType === RedirectionType.TABLE_DATA) {
          this.processing = false;
          this.planData = new BatchPlus(event.data.tableData);
          this.storageService.setComparisonPlanData(this.planData);
          this.logEvent(BATCH_PLUS_EVENT.PLUS_COMPARE_POPUP);
          this.handleBuyNowClickedEvent();
          return;
        }

        if (event.data.redirectionType === RedirectionType.SELECTED_PLAN) {
          this.selectedPlanId = event.data.selectedPlan;
          this.logEvent(
            BATCH_PLUS_EVENT.PLUS_POPUP_ACTION,
            event?.data?.planName
          );
          return;
        }

        if (event.data.redirectionType === RedirectionType.BACK) {
          this.logEvent(BATCH_PLUS_EVENT.PLUS_POPUP_ACTION, 'popup_close');

          if (this.openFromBatchListing) {
            const defaultPlan = this.getPlusDefaultPlan(this.planData);

            const data = {
              batchSlug: this.batchData?._id,
              planId: defaultPlan?._id,
              cameFrom: this.source,
            };

            this.storageService.setRouteToNewFbtToSessionStorage();
            const info = Base64.encode(JSON.stringify(data));
            this.router.navigate(['/payments'], {
              queryParams: {
                redirect_url: '/',
                info,
              },
            });
          }
        }
        this.dialog.close(event.data.redirectionType);

        if (event.data.redirectionType === RedirectionType.BUY_NOW) {
          // this.logEvent(BATCH_PLUS_EVENT.PLUS_POPUP_ACTION, 'buy_now');
          this.logEvent(BATCH_PLUS_EVENT.BUY_NOW_CLICKED);
          this.storageService.setBatchPlanId(event.data.selectedPlan);

          this.batchService.onBuyNowClick(this.batchData?._id);

          if (event.data.cameFrom === 'upgrade_cta') {
            const batchPlan: BatchPlan = this.planData.plans.find(
              (plan: BatchPlan) => plan?._id === event.data.selectedPlan
            )!;

            this.paymentService.setSelectedBatchPlusPlan(batchPlan);

            this.router.navigate([], {
              relativeTo: this.activatedRoute,
              queryParams: {
                planId: event.data.selectedPlan,
              },
              queryParamsHandling: 'merge',
              replaceUrl: true,
            });
            return;
          }

          this.storageService.setLastVisitedUrl(this.router.url);

          if (this.routeToNewFbt) {
            const data = {
              batchSlug: this.batchData?._id,
              planId: event.data.selectedPlan,
              cameFrom:
                this.source === BATCH_ENUMS.UPGRADE_BANNER
                  ? BATCH_ENUMS.UPGRADE_BANNER
                  : this.source === Source.COMPARE_PLANS_CTA
                  ? 'batch-details'
                  : this.source,
            };

            this.storageService.setRouteToNewFbtToSessionStorage();
            const info = Base64.encode(JSON.stringify(data));
            this.router.navigate(['/payments'], {
              queryParams: {
                redirect_url: '/',
                info,
              },
            });
            return;
          }

          const orderQueryParams: any = {
            planId: event.data.selectedPlan,
          };

          if (this.source === BATCH_ENUMS.UPGRADE_BANNER) {
            orderQueryParams['cameFrom'] = BATCH_ENUMS.UPGRADE_BANNER;
          }

          this.router.navigate(
            [`batches/${this.batchData?.slug}/batch-overview/order-summary`],
            {
              queryParams: orderQueryParams,
            }
          );
          return;
        }

        if (event.data.redirectionType === RedirectionType.DEMO_VIDEOS) {
          this.logEvent(BATCH_PLUS_EVENT.KNOW_MORE_CLICK);
          this.logEvent(BATCH_PLUS_EVENT.PLUS_POPUP_ACTION, 'know_more');

          const planIndex = this.planData?.plans.findIndex(
            (p: BatchPlan) => p?._id === event.data.selectedPlan
          );

          const defaultPlan = this.planData?.plans?.find(
            (plan) => plan?.isRecommended
          );

          const currentPlan = this.planData?.plans?.find(
            (plan) => plan?.isPlanPurchased
          );

          const videoObject = {
            planVideoId: this.planData?.plans[planIndex]?.planVideoId,
            videoDetails: this.planData?.plans[planIndex]?.videoDetails,
            mainSheetTitle: this.planData?.plans[planIndex]?.mainSheetTitle,
            popupCtaName: this.displayPopCTAName(this.source),
            defaultPlan: defaultPlan?.title || '',
            currentPlan: currentPlan?.title || '',
            insidePopup: 'yes',
            pageName: this.getSourceName(),
          };
          const videoDetails = btoa(JSON.stringify(videoObject));

          if (this.openFromBatchListing) {
            const childData = {
              action: 'CHILD_NAVIGATION',
              applicationType: 'NEW_BATCH_SERVICE',
              pathname: `/batches-new/batches/${this.batchData?._id}/demo-videos`,
              value: `/batches-new/batches/${this.batchData?._id}/demo-videos?video_details=${videoDetails}`,
            };
            this.componentIframe?.contentWindow?.postMessage(childData, '*');
            return;
          }

          this.storageService.setSessionValue(
            STORAGE_ENUM.BATCH_SOURCE_URL,
            this.router.url,
            'string'
          );

          this.router.navigate([`/batches`], {
            queryParams: {
              batchChildUrl: `/batches-new/batches/${this.batchData?._id}/demo-videos?video_details=${videoDetails}`,
              isBatchSourceUrl: true,
            },
          });
          return;
        }

        if (event.data.openFromBatchListing) {
          this.selectedPlanId = event.data.selectedPlan;

          if (event.data.cameFrom === CameFrom.BUY_NOW_CTA) {
            this.storageService.setLastVisitedUrl(this.router.url);
            this.batchService.onBuyNowClick(this.batchData?._id);

            if (this.routeToNewFbt) {
              const data = {
                batchSlug: this.batchData?._id,
                planId: event.data.selectedPlan,
                cameFrom:
                  this.source === BATCH_ENUMS.UPGRADE_BANNER
                    ? BATCH_ENUMS.UPGRADE_BANNER
                    : Source.BATCH_LISTING,
              };

              this.storageService.setRouteToNewFbtToSessionStorage();
              const info = Base64.encode(JSON.stringify(data));
              this.router.navigate(['/payments'], {
                queryParams: {
                  redirect_url: '/',
                  info,
                },
              });
              return;
            }

            this.router.navigate(
              [`batches/${this.batchData?.slug}/batch-overview/order-summary`],
              {
                queryParams: {
                  planId: this.selectedPlanId,
                },
              }
            );
          } else if (event.data.cameFrom === CameFrom.EXPLORE_CTA) {
            this.router.navigate(
              [`batches/${this.batchData?.slug}/batch-overview`],
              {
                queryParams: {
                  planId: this.selectedPlanId,
                },
              }
            );
          }
          return;
        }
      }
    });
  }

  handleBuyNowClickedEvent() {
    if (this.openFromBatchListing && this.cameFrom === CameFrom.BUY_NOW_CTA) {
      const defaultPlan = this.planData?.plans?.find(
        (plan) => plan?.isRecommended
      );

      const currentPlan = this.planData?.plans?.find(
        (plan) => plan?.isPlanPurchased
      );

      const selectedPlan = this.planData?.plans.find(
        (plan) => plan?._id === this.selectedPlanId
      );
      const userType =
        this.globalService.getIsPathShalaFromStorage() === 'false'
          ? 'online'
          : 'offline';

      const isAnyPlanPurchased = this.planData?.plans?.some(
        (plan) => plan?.isPlanPurchased
      );

      const data = {
        current_plan: currentPlan?.title || '',
        default_plan: defaultPlan ? defaultPlan?.title : '',
        plan_selected: selectedPlan ? selectedPlan?.title : defaultPlan?.title,
        batch_name: this.batchData.name,
        batch_id: this.batchData._id,
        exam: this.batchData.exam,
        class: this.batchData.class,
        boards: this.batchData.board,
        batch_type: this.batchData.type,
        page_name: 'batch_listing_page',
        popup_cta_name: 'buy_now',
        cta_name: isAnyPlanPurchased ? 'upgrade_now' : 'buy_now',
        is_plus_compare_popup: 'no',
        is_locked_popup: 'no',
        is_plus_enabled: this.batchData?.isBatchPlusEnabled ? 'yes' : 'no',
        user_type: userType,
      };
      this.firebaseService.logEvents(
        BATCH_DISCOVERABILITY_EVENTS.BUY_NOW_CLICKED,
        data,
        false,
        true,
        true,
        true,
        false
      );
    }
  }

  checkLoaded(event: any, iframe: HTMLIFrameElement) {
    this.iframe = iframe;
  }

  getPlusDefaultPlan(plansData: BatchPlus): BatchPlan {
    let activeIndex = 0;
    let lastPurchasedPlanIdx = this.findLastIndex(
      plansData?.plans,
      (plan: BatchPlan) => plan?.isPlanPurchased
    );

    activeIndex =
      lastPurchasedPlanIdx < plansData.plans.length - 1
        ? lastPurchasedPlanIdx + 1
        : lastPurchasedPlanIdx;
    return plansData?.plans?.[activeIndex] ?? new BatchPlan({});
  }

  findLastIndex(arr: any[], condition: (ele: any) => boolean) {
    for (let i = arr.length - 1; i >= 0; i--) {
      if (condition(arr[i])) {
        return i;
      }
    }
    return -1;
  }
}

enum Source {
  BATCH_LISTING = 'batch_listing',
  FBT = 'fbt',
  COMPARE_PLANS_CTA = 'compare_plans_cta',
  KHAZANA_PAGE = 'khazana_page',
  INFINITE_PRACTICE = 'infinite_practice',
  INFINITE_MENTORSHIP = 'infinite_mentorship',
  SAARTHI = 'saarthi',
  ALL_CLASSES = 'all_classes',
  ALL_CLASSES_SUBJECTS = 'all_classes_subjects',
  TEST_SERIES_PAGE = 'test_series_page',
  UPGRADE_BANNER = 'upgrade_banner',
  BATCH_CLUBBING = 'batch_clubbing',
  BATCH_DESCRIPTION_PAGE = 'batch_description_page',
  LOCKED_OFFERING = 'locked_offering',
  TEST_SERIES_ACCESS_PAGE = 'test_series_access_page',
}

enum RedirectionType {
  SELECTED_PLAN = 'SELECTED_PLAN',
  DEMO_VIDEOS = 'DEMO_VIDEOS',
  BUY_NOW = 'BUY_NOW',
  BACK = 'BACK',
  PAGE_LOAD = 'PAGE_LOAD',
  TABLE_DATA = 'TABLE_DATA',
}

enum CameFrom {
  BUY_NOW_CTA = 'buy_now_cta',
  EXPLORE_CTA = 'explore_cta',
}
