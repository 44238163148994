import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { BatchService } from 'src/app/common/services/batch/batch.service';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import {
  Component as TileComponent,
  Placeholder,
  Widget,
} from 'src/app/common/services/study/study.service';
import {
  DASHBOARD_IMG,
  NEW_BADGE,
  RIGHT_ARROW_IMG,
} from 'src/app/core/assets.location';
import { WIDGET_CLICK } from 'src/app/core/analytics-events';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnChanges {
  dashboardImg = DASHBOARD_IMG;
  newImg = NEW_BADGE;
  right_arrow = RIGHT_ARROW_IMG;
  @Input() widget: Widget;
  @Input() item: TileComponent;
  @Input() variant: 'allclasses' | 'default' = 'default';
  isShowMyDashboard = false;

  constructor(
    private router: Router,
    private batchService: BatchService,
    private showErrorService: ShowErrorService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {}

  async ngOnInit() {
    const _item = this.item || this.widget;
    const batchesData = _item?.config?.dashboard?.filter(
      (res: any) => res.type === 'Batch'
    )[0];
    const TestData = _item?.config?.dashboard?.filter(
      (res: any) => res.type === 'TestSeries'
    )[0];
    if (batchesData || TestData) {
      if (batchesData) {
        await this.getBatches(
          batchesData?.date,
          batchesData?.condition?.basedOn,
          TestData
        );
      }
      if (TestData) {
        await this.getTestList(TestData?.date, TestData?.condition?.basedOn);
      }
    } else {
      this.isShowMyDashboard = false;
    }
  }

  goToMyDashBoard() {
    this.router.navigate(['batches/study/my-dashboard'], {
      queryParams: {
        coming_source: this.variant === 'allclasses' ? 'allclasses' : 'study',
      },
    });

    this.logStudyEvent('My Dashboard');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.variant) {
      this.variant = changes.variant.currentValue;
    }

    if (changes && changes.item) {
      this.item = changes.item.currentValue;
    }
  }

  logStudyEvent(url: string): void {
    const data = {
      widgetid: this.widget?._id,
      widget_type: this.widget?.type,
      widget_redirection: url,
      placeholder: Placeholder.STUDY_PAGE,
    };
    this.firebaseAnalytics.logEvents(
      WIDGET_CLICK,
      data,
      false,
      true,
      true,
      true,
      true
    );
  }

  async getBatches(date: Date, condition: string, testData: any) {
    if (!date || !condition) return;
    const query: any = {};
    query[condition] = new Date(date).toISOString().slice(0, 10);
    try {
      const res = await lastValueFrom(
        this.batchService.getBatchListForDashBoard(query)
      );
      if (res && res.length > 0) {
        this.isShowMyDashboard = true;
      } else {
        if (!testData) {
          this.isShowMyDashboard = false;
        }
      }
    } catch (error) {
      //this.showErrorService.showError(error);
      if (!testData) {
        this.isShowMyDashboard = false;
      }
    }
  }

  async getTestList(date: Date, condition: string) {
    if (!date || !condition) return;
    const query: any = {};
    query[condition] = new Date(date).toISOString().slice(0, 10);
    try {
      const res = await lastValueFrom(
        this.batchService.getTestListForDashBoard(query)
      );
      if (res && res.length > 0) {
        this.isShowMyDashboard = true;
      } else {
        if (!this.isShowMyDashboard) {
          this.isShowMyDashboard = false;
        }
      }
    } catch (error) {
      // this.showErrorService.showError(error);
      if (!this.isShowMyDashboard) {
        this.isShowMyDashboard = false;
      }
    }
  }
}
