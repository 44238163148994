<article
  class="video-card rounded-md my-3 py-2"
  [ngClass]="{
    'opacity-50': lectureData.status === 'CANCELED',
    'no-rating-video-card': !showRating
  }"
>
  <div
    (click)="goToVideo(lectureData)"
    class="relative video-card-img mx-2 cursor-pointer"
    [ngClass]="{ 'cursor-not': lectureData.status === 'CANCELED' }"
  >
    <div
      class="teacher-img flex flex-col w-full justify-end items-center rounded relative"
      *ngIf="
        lectureData?.teacherImage?.baseUrl &&
        lectureData?.teacherImage?.key &&
        !lectureData?.videoDetails?.image
      "
    >
      <img
        [src]="lectureData.teacherImage | getImagePipe"
        alt="PW"
        width="88px"
      />
      <img
        [src]="
          lectureData.scheduleType === 'premiumCohort' &&
          lectureData.tag === 'Live'
            ? redPlayBtn
            : lectureData.scheduleType === 'premiumCohort' &&
              lectureData.tag === 'Ended'
            ? bluePlayBtn
            : time === 'Live'
            ? redPlayBtn
            : time === 'endLive'
            ? bluePlayBtn
            : ''
        "
        height="20"
        width="20"
        class="absolute play-img z-40"
        alt="play-icon"
        *ngIf="time === 'Live' || time === 'endLive' || time === 'Ended'"
      />
      <div class="teacher-name w-full relative">
        <div class="absolute name w-full flex justify-center">
          <span
            class="text-white caption-1 bold !text-ellipsis"
            [matTooltip]="
              lectureData.teacherName.length > 32 &&
              lectureData.status !== 'CANCELED'
                ? lectureData.teacherName
                : ''
            "
            >{{ lectureData.teacherName | truncateText : 32 }}</span
          >
        </div>
      </div>
    </div>

    <div class="relative">
      <img
        [src]="lectureData | getVideoImg"
        class="video-poster rounded"
        [ngClass]="{ 'grayscale-img': time === 'endlive' }"
        *ngIf="
          !lectureData?.teacherImage?.baseUrl ||
          !lectureData?.teacherImage?.key ||
          lectureData?.videoDetails?.image
        "
      />
      <img
        [src]="
          lectureData.scheduleType === 'premiumCohort' &&
          lectureData.tag === 'Live'
            ? redPlayBtn
            : lectureData.scheduleType === 'premiumCohort' &&
              lectureData.tag === 'Ended'
            ? bluePlayBtn
            : time === 'Live'
            ? redPlayBtn
            : time === 'endLive'
            ? bluePlayBtn
            : ''
        "
        height="20"
        width="20"
        class="play-icon"
        alt="play-icon"
        *ngIf="time === 'Live' || time === 'endLive' || time === 'Ended'"
      />
    </div>

    <mat-progress-bar
      mode="determinate"
      color="primary"
      [value]="progress"
      *ngIf="['endLive'].includes(time) && progress > 0"
      class="video-progress-bar"
    ></mat-progress-bar>
  </div>
  <!-- pt-3 pb-2 -->
  <section
    class="content-details flex flex-col gap-1 my-2 px-2 cursor-pointer"
    [ngClass]="{
      'cursor-not': lectureData.status === 'CANCELED',
      'progress-not': !['endLive'].includes(time)
    }"
  >
    <div class="flex justify-between" (click)="goToVideo(lectureData)">
      <ng-container *ngIf="lectureData.status !== 'CANCELED'">
        <div
          class="class-live"
          [ngClass]="{
            'class-live': !lectureData.isSimulatedLecture,
            'class-ongoing': lectureData.isSimulatedLecture
          }"
          *ngIf="
            time === 'Live' &&
            (isOnline ||
              lectureData?.videoDetails?.embedCode ||
              lectureData?.videoDetails?.hls_url ||
              lectureData?.videoDetails?.videoUrl ||
              lectureData?.url)
          "
        >
          <ng-lottie
            *ngIf="!lectureData?.isSimulatedLecture"
            (animationCreated)="animationCreated($event)"
            [options]="liveLottie"
          >
          </ng-lottie>

          <div class="ongoing-class" *ngIf="lectureData?.isSimulatedLecture">
            ONGOING
          </div>
        </div>

        <div
          class="class-incoming px-2 rounded bg-white"
          *ngIf="
            (time !== 'Live' && time !== 'endLive' && time !== 'Ended') ||
            time === 'Upcoming'
          "
        >
          <span class="caption-1 semibold">Upcoming</span>
        </div>

        <div
          class="class-ongoing px-2 rounded text-white"
          *ngIf="
            time === 'Live' &&
            !isOnline &&
            !lectureData?.videoDetails?.embedCode &&
            !lectureData?.videoDetails?.hls_url &&
            !lectureData?.videoDetails?.videoUrl &&
            !lectureData.url
          "
        >
          <span class="caption-1 semibold">Ongoing</span>
        </div>
        <div
          class="flex gap-1 items-center video-time"
          *ngIf="time === 'endLive' && !showDate"
        >
          <img class="w-4 h-4" [src]="clockImage" />
          <span class="caption-1">
            {{ lectureData?.videoDetails?.duration || duration }}
          </span>
        </div>
        <div
          class="flex gap-1 items-center video-time"
          *ngIf="time === 'endLive' && showDate"
        >
          <span class="caption-1">
            {{
              lectureData?.date || lectureData?.videoDetails?.createdAt || ''
                | date : 'd MMMM, y'
            }}
          </span>
        </div>
      </ng-container>
      <div class="flex gap-1 items-center video-time" *ngIf="!replaceStartTime">
        <img class="w-4 h-4" [src]="clockImage" />
        <span class="caption-1">
          {{ lectureData.startTime | date : 'shortTime' }}
        </span>
      </div>
      <div class="flex gap-1 items-center video-time" *ngIf="replaceStartTime">
        <img class="w-4 h-4" [src]="clockImage" />
        <span class="caption-1">
          {{ lectureData?.videoDetails?.duration || duration }}
        </span>
      </div>
      <div
        class="class-cancel rounded-sm"
        *ngIf="lectureData.status === 'CANCELED'"
      >
        <span class="caption-1 semibold">Cancelled</span>
      </div>
    </div>
    <span
      [matTooltip]="
        lectureData.status !== 'CANCELED' && title.length > 30 ? title : ''
      "
      class="lecture-title body-2 bold text-start"
    >
      {{ title | getVideoName }}
    </span>
  </section>
  <div *ngIf="showRating">
    <hr class="mb-2 hr-line" />
    <section
      class="px-2 flex items-center gap-8 pb-1 w-full"
      *ngIf="variant === 'study'"
    >
      <div class="flex flex-1 justify-between rating-card">
        <img
          *ngFor="let items of stars; index as i"
          (click)="setStarRating(items)"
          [src]="items <= lectureData.rating ? ratedImg : notRatedImg"
          class="star_img cursor-pointer h-6 w-6 opacity-70"
          [ngClass]="{
            'opacityfull cursor-not': lectureData.rating > 0,
            'cursor-not': lectureData.status === 'CANCELED'
          }"
        />
      </div>
      <button
        class="tell-us rounded caption-1 semibold whitespace-nowrap"
        *ngIf="lectureData.rating > 0"
        (click)="openRatingDialog('click')"
        [disabled]="
          lectureData && lectureData.feedback && lectureData.feedback.length > 0
        "
        [ngClass]="{
          'cursor-not-allowed opacity-50':
            lectureData.feedback.length > 0 || lectureData.status === 'CANCELED'
        }"
      >
        Tell us more
      </button>
    </section>
    <section
      class="px-2 flex flex-col items-start w-full"
      *ngIf="variant === 'weekly'"
    >
      <div class="flex justify-between w-full">
        <!-- stars -->
        <div class="flex flex-1 justify-between rating-card">
          <img
            *ngFor="let items of stars; index as i"
            (click)="setStarRating(items)"
            [src]="items <= lectureData.rating ? ratedImg : notRatedImg"
            class="star_img cursor-pointer h-6 w-6 opacity-70"
            [ngClass]="{
              'opacityfull cursor-not': lectureData.rating > 0,
              'cursor-not': lectureData.status === 'CANCELED'
            }"
          />
        </div>
        <div class="flex justify-end items-center w-2/6">
          <img
            class="mark-complet-tick"
            [src]="markedCompleteIcon"
            *ngIf="lectureData.isComplete && !lectureData.isDPPVideos"
          />
          <span
            [matMenuTriggerFor]="menu"
            class="flex cursor-pointer"
            *ngIf="!lectureData.isDPPVideos"
          >
            <mat-icon>more_vert</mat-icon>
          </span>
          <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
            <div class="menu-panel flex flex-col">
              <div
                [ngClass]="{
                  'video-completed':
                    !canMarkAsComplete || lectureData.isComplete
                }"
                class="flex p-3 gap-1.5 border-b cursor-pointer hover:bg-gray-100"
                (click)="markComplete()"
              >
                <img
                  [src]="markCompleteIcon"
                  alt="mark-complete"
                  class="menu-option-img"
                />
                <span>Mark as Complete</span>
              </div>

              <div
                *ngIf="
                  lectureData &&
                  lectureData.homeworkIds &&
                  lectureData.homeworkIds.length > 0
                "
                class="flex p-3 gap-1.5 cursor-pointer hover:bg-gray-100"
                (click)="openAttachmentsDialog(lectureData, $event)"
              >
                <img
                  [src]="addAttachmentIcon"
                  alt="add-attachment"
                  class="menu-option"
                />
                <span>Attachments</span>
              </div>
            </div>
          </mat-menu>
        </div>
      </div>
      <!-- tell us more -->
      <button
        class="tell-us rounded caption-1 semibold whitespace-nowrap"
        *ngIf="lectureData.rating > 0"
        (click)="openRatingDialog('click')"
        [disabled]="
          lectureData && lectureData.feedback && lectureData.feedback.length > 0
        "
        [ngClass]="{
          'cursor-not-allowed opacity-50':
            lectureData.status === 'CANCELED' ||
            (lectureData.feedback && lectureData.feedback.length > 0)
        }"
      >
        Tell us more
      </button>
    </section>
  </div>
</article>
