<div class="tile-container cursor-pointer relative">
  <img *ngIf="isNew" [src]="newTag" alt="new-tag" class="new-tag" />
  <div
    class="flex justify-end"
    *ngIf="isBattleGroundWidget && cameFrom === 'classroom' && battleNudgeText"
  >
    <span class="battle-ground-nudge">
      {{ battleNudgeText }}
    </span>
  </div>
  <div
    class="flex justify-end"
    *ngIf="isBattleGroundWidget && battleStatus && cameFrom === 'study'"
  >
    <ng-lottie [options]="redDotLottie" height="30px"></ng-lottie>
  </div>
  <img [src]="image" alt="" class="tile-image" />

  <div class="flex flex-col mt-2">
    <div>
      <span [innerHtml]="title | safe : 'html'" class="title"></span>
    </div>
    <div>
      <span [innerHtml]="subTitle | safe : 'html'" class="subtitle"></span>
    </div>
  </div>
</div>
