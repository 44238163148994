import { Injectable } from '@angular/core';
import { catchError, lastValueFrom, map } from 'rxjs';
import { BatchDetailModel } from 'src/app/pages/batch/batch-overview/batch-overview.model';
import { PPApiService } from '../../api/api.service';
import { PPApiOptions } from '../../api/api.type';
import { AppUrlService } from '../../app-url/app-url.service';
import { handleError } from '../error-handler/error-handler.service';
import { FirebaseAnalyticsService } from '../firebase-analytics/firebase-analytics.service';
import { GlobalService } from '../global/global.service';
import { ShowErrorService } from '../showError/show-error.service';
import {
  TestInstruction,
  TestSeriesService,
} from '../tests/test-series.service';
import { BatchService } from './../batch/batch.service';
import { PW_SCHOLARSHIP_TEST_SERIES } from '../sat/pw-sat.service';

@Injectable({
  providedIn: 'root',
})
export class PracticeService {
  constructor(
    private testService: TestSeriesService,
    private showErrorService: ShowErrorService,
    private batchService: BatchService,
    private globalService: GlobalService,
    private firebaseService: FirebaseAnalyticsService,
    private appUrlService: AppUrlService,
    private apiService: PPApiService
  ) {}

  // Redirection Handling from
  // ----------> Library
  // ----------> Library -> View All Page
  // ----------> Library -> Recent Chapters
  // ----------> Study -> Recent Learning
  // ----------> Weekly Schedule
  // ----------> All Contents

  async startPractice({
    practiceData,
    source,
    fetchInstructions,
    practiceType,
    localstorageKeysToClear,
    scheduleId,
    saarthi,
    eventsData,
    subjectName,
    batchName,
    batchId,
    testModeId,
    testMapId,
    mode,
    contentId,
    testCategoryId,
    satRegistrationId,
  }: StartPracticeProps) {
    fetchInstructions = fetchInstructions || false;
    localstorageKeysToClear = localstorageKeysToClear || [];
    eventsData = eventsData || {
      source: undefined,
    };
    saarthi = {
      enabled: false,
      batchId: '',
      isAskSaarthiEnabled: false,
      isPremium: false,
      ...saarthi,
    };

    if (!this.globalService.isEmpty(eventsData))
      //  @ts-ignore
      this.handleEventsData(eventsData);

    // Keys to clear from localstorage if provided by user
    if (
      localstorageKeysToClear.length > 0 &&
      typeof localStorage !== 'undefined'
    ) {
      setTimeout(() => {
        localstorageKeysToClear?.forEach((_key) => {
          localStorage.removeItem(_key);
        });
      }, 100);
    }

    const { enable_saarthi, saarthi_id } = await this.getSaarthiQuery(saarthi);

    if (batchId) await this.getBatchDetails(batchId as string);
    const practiceId: string =
      practiceData?.test?._id ||
      (practiceData?.content &&
        (practiceData?.content[0]?.exerciseId?._id ||
          practiceData?.content[0]?.exerciseDetails?._id ||
          practiceData?.content[0]?.exerciseDetails?.slug ||
          practiceData?.content[0]?.exerciseId)) ||
      practiceData?._id ||
      '';

    let instructions = <TestInstruction>{};

    //  Fetch instructions only when asked
    if (fetchInstructions) {
      instructions = await this.fetchInstructionsV3(practiceId);

      this.logDppCardClick(
        practiceId,
        batchId as string,
        instructions.name,
        instructions.tag2,
        eventsData?.source as string
      );
      this.redirectTo({
        practiceType,
        destination:
          instructions.tag2 === DPPType.REATTEMPT ? 'RESULT' : 'ENGINE',
        practiceId: practiceId,
        queryObject: {
          type: instructions?.tag2,
          source,
          ...(!!scheduleId ? { scheduleId } : {}),
          ...(!!batchName ? { batchName } : {}),
          ...(enable_saarthi ? { enable_saarthi } : {}),
          ...(enable_saarthi ? { saarthi_id } : {}),
          ...(saarthi?.isPremium ? { isPremium: true } : {}),
          ...(!!subjectName ? { subjectName } : {}),
          ...(!!batchId ? { batchId } : {}),
        },
      });
    } else {
      if (practiceType === 'dpp' || practiceType === 'smart-dpp') {
        this.logDppCardClick(
          practiceId,
          batchId as string,
          practiceData?.name || practiceData?.test?.name || '',
          practiceData?.tag || practiceData?.tag2 || '',
          eventsData?.source?.toLowerCase() || '',
          practiceData?.test?.smartDPP ? 'smart' : 'normal'
        );

        this.redirectTo({
          practiceType,
          destination:
            practiceData.testActivityStatus === TestActivityStatus.FINISHED ||
            practiceData.testActivityStatus === TestActivityStatus.SUBMITTED ||
            practiceData?.tag === DPPType.REATTEMPT
              ? 'RESULT'
              : 'ENGINE',
          practiceId,
          queryObject: {
            type: practiceData?.tag || practiceData?.tag2,
            source,
            ...(!!scheduleId ? { scheduleId } : {}),
            ...(!!batchName ? { batchName } : {}),
            ...(enable_saarthi ? { enable_saarthi } : {}),
            ...(enable_saarthi ? { saarthi_id } : {}),
            ...(saarthi?.isPremium ? { isPremium: true } : {}),
            ...(!!subjectName ? { subjectName } : {}),
            ...(!!contentId ? { contentId } : ''),
            ...(!!batchId ? { batchId } : {}),
          },
        });
      } else {
        this.redirectTo({
          practiceType,
          destination:
            practiceData?.tag2 === DPPType.START ||
            practiceData?.tag2 === DPPType.RESUME ||
            practiceData?.tag2 === DPPType.RESTART ||
            (practiceData?.tag2 === DPPType.REATTEMPT && satRegistrationId)
              ? 'INSTRUCTION'
              : 'RESULT',
          practiceId,
          queryObject: {
            type: practiceData?.tag || practiceData?.tag2,
            source,
            ...(!!testModeId ? { testModeId } : {}),
            ...(!!batchId ? { batchId } : {}),
            ...(testMapId ? { testMapId } : {}),
            ...(testCategoryId ? { testCategoryId } : {}),
            comingFrom: PW_SCHOLARSHIP_TEST_SERIES.PW_SCHOLARSHIP,
            satRegistrationId: satRegistrationId ?? '',
          },
        });
      }
    }
  }

  private async getSaarthiQuery(saarthi: Saarthi | undefined) {
    let batchData: BatchDetailModel = <BatchDetailModel>{};
    let saarthiObj: any;
    let _saarthiTypeId: string = '';
    if (saarthi?.enabled) {
      _saarthiTypeId =
        (typeof localStorage !== 'undefined' &&
          localStorage.getItem('addOnSaarthiTypeId')) ||
        '';

      batchData = await this.getBatchDetails(saarthi?.batchId);

      if (
        (saarthi.isAskSaarthiEnabled || batchData?.isAskSaarthiEnabled) &&
        !_saarthiTypeId
      ) {
        let addOns: any = {};
        try {
          addOns = await lastValueFrom(
            this.batchService.getAddOnsList(saarthi.batchId)
          );
        } catch (error: any) {
          console.log(error?.message || 'Something Went wrong');
        } finally {
          saarthiObj =
            addOns?.filter((item: any) => item?.type === 'SAARTHI')[0] || {};
        }
      }
    }

    const _premiumTypeId = localStorage.getItem('premium_saarthi') ?? '';

    if (saarthi?.isPremium) {
      saarthiObj = {
        typeId: _premiumTypeId,
      };
    }

    return {
      enable_saarthi:
        ((saarthi?.isAskSaarthiEnabled ||
          batchData?.isAskSaarthiEnabled ||
          saarthi?.isPremium) &&
          (saarthiObj?.typeId?.length > 0 || _saarthiTypeId.length > 0)) ||
        false,
      saarthi_id: saarthi?.isPremium
        ? _premiumTypeId
        : _saarthiTypeId || saarthiObj?.typeId || '',
    };
  }

  private async getBatchDetails(batchId: string) {
    const _batchDetailsFromLocal = this.batchService.getBatchData();
    let batchData = _batchDetailsFromLocal;
    if (this.globalService.isEmpty(_batchDetailsFromLocal)) {
      try {
        batchData = await lastValueFrom(
          this.batchService.getBatchDetails(batchId)
        );
        this.batchService.setBatchData(batchData);
      } catch (error: any) {
        console.log(error?.message || 'Something Went wrong');
      }
    }
    return batchData;
  }

  private redirectTo({
    queryObject,
    destination,
    practiceId,
    practiceType,
  }: RedirectToProps) {
    queryObject = queryObject || {};
    const queryString = this.objectToQueryString(queryObject) || '';
    if (typeof window !== 'undefined') {
      // base path for practice
      let _url = `/practice/${practiceType}/${practiceId}`;

      if (destination === 'INSTRUCTION') {
        _url += '/instructions';
      }

      if (destination === 'RESULT') {
        _url += `/result`;
      }

      const _finalUrl =
        _url + (queryString.length > 0 ? `?${queryString}` : '');

      window.location.href = window.origin + _finalUrl;
    }
  }

  redirectToCombinedResult({
    practiceId,
    practiceType,
    queryObject,
  }: RedirectToCombinedResultProps) {
    queryObject = queryObject || {};

    const queryString = this.objectToQueryString(queryObject) || '';
    if (typeof window !== 'undefined') {
      let _url = `/practice/${practiceType}/${practiceId}/result/test-group-result`;

      const _finalUrl =
        window.origin +
        _url +
        (queryString.length > 0 ? `?${queryString}` : '');
      window.open(_finalUrl, '_blank');
    }
  }

  private async fetchInstructionsV3(dppId: string) {
    let instructions = <TestInstruction>{};
    try {
      const res = await lastValueFrom(
        this.testService.getTestInstructionsV3(dppId)
      );
      if (res) instructions = <TestInstruction>res;
    } catch (e) {
      this.showErrorService.showError(e);
    }

    return instructions;
  }

  private objectToQueryString(obj: Record<string, string | boolean>) {
    const queryString = Object.keys(obj)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
      )
      .join('&');

    return queryString;
  }

  private handleEventsData(eventsData: EventsData) {
    // Return if object is empty
    if (this.globalService.isEmpty(eventsData)) return;

    //
    if (eventsData.source) {
      localStorage.setItem('practiceSource', eventsData.source);
    }
  }

  private logDppCardClick(
    dppId: string,
    batchId: string,
    dppName: string,
    status: string,
    source: string,
    dpp_type?: string
  ) {
    this.firebaseService.logEvents(
      'dpp_card_click',
      {
        dppId,
        batchId,
        dpp_name: dppName,
        status,
        source,
        dpp_type,
      },
      true,
      true,
      true,
      true,
      true
    );
  }

  private getUnleashFeatureFlag(data: UnleashApiPayloadType) {
    const url = this.appUrlService.UNLEASH_FEATURE_FLAG();
    const options: PPApiOptions = {
      apiPath: url,
    };

    return this.apiService
      .post(data, options)
      .pipe(map((res: any) => res.data));
  }

  private async getUnleashFeatureStatus(
    data: UnleashApiPayloadType
  ): Promise<UnleashDataType> {
    try {
      const res = await lastValueFrom(this.getUnleashFeatureFlag(data));
      if (res) {
        return <UnleashDataType>res;
      }
      return <UnleashDataType>{};
    } catch (error) {
      return <UnleashDataType>{};
    }
  }
}

// ENUM START
enum DPPType {
  REATTEMPT = 'Reattempt',
  RESUME = 'Resume',
  START = 'Start',
  RESTART = 'Restart',
}

enum TestActivityStatus {
  STARTED = 'Started',
  FINISHED = 'Finished',
  SUBMITTED = 'Submitted',
}
// ENUM END

// TYPE START
type StartPractice = {
  practiceData: any;
  fetchInstructions?: boolean;
  practiceType: PracticeType;
  localstorageKeysToClear?: string[];
  scheduleId?: string;
  saarthi?: Saarthi;
  subjectName?: string;
  batchName?: string;
  eventsData?: EventsData;
  batchId?: string;
  testModeId?: string;
  testMapId?: string;
  mode?: string;
  contentId?: string;
  testCategoryId?: string;
  satRegistrationId?: string;
} & (
  | {
      source: Source;
    }
  | {
      source: LIBRARY;
      cohortId: string;
    }
);

type Saarthi = {
  enabled: boolean;
  batchId: string;
  isAskSaarthiEnabled?: boolean;
  isPremium?: boolean;
};

type RequireCohortId<T> = T extends { source: LIBRARY }
  ? { cohortId: string }
  : {};

type StartPracticeProps = StartPractice & RequireCohortId<StartPractice>;

type RedirectToProps = {
  destination: Destination;
  practiceId: string;
  practiceType: PracticeType;
  queryObject: Record<string, string | boolean>;
};

type RedirectToCombinedResultProps = {
  destination?: Destination;
  practiceId: string;
  practiceType: PracticeType;
  queryObject: Record<string, string | boolean>;
};

type EventsData = {
  source?: PracticeSource;
};

type Destination = 'RESULT' | 'ENGINE' | 'INSTRUCTION';
type Source =
  | 'BATCH_QUIZ'
  | 'BATCH_TEST_SERIES'
  | 'TEST_SERIES'
  | 'PW_SCHOLARSHIP';
type LIBRARY = 'LIBRARY';
type PracticeType = 'dpp' | 'test' | 'smart-dpp';
type FetchDetailsFrom = 'LOCAL_STORAGE' | 'SERVER';
type PracticeSource =
  | 'RECENT_LEARNING'
  | 'BATCH_SECTION'
  | 'LIBRARY'
  | undefined;
// TYPE END

interface UnleashApiPayloadType {
  userId?: string;
  featureName: string;
  experimentName?: string;
  customData?: any;
  sendAnalytics?: boolean;
}

interface UnleashDataType {
  isEnabled: boolean;
  variantData: VariantData;
}

interface VariantData {
  name: string;
  payload: Payload;
  enabled: boolean;
  feature_enabled: boolean;
  featureEnabled: boolean;
}

interface Payload {
  type: string;
  value: string;
}
