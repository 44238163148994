<div class="h-full">
  <div class="flex justify-end sticky top-0 w-full bg-white py-2">
    <mat-icon
      *ngIf="!isMandatory && !hideCrossIcon"
      class="cursor-pointer"
      (click)="handleClose()"
      >close</mat-icon
    >
  </div>

  <iframe
    #admitCardFrame
    class="responsive-iframe"
    id="admit-card-frame"
    (load)="checkLoaded($event, admitCardFrame)"
    [src]="url | safe : 'resourceUrl'"
    [style.height]="hideCrossIcon && 'fit-content'"
  ></iframe>
</div>
