import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { PPApiService } from 'src/app/common/api/api.service';
import { PPApiOptions } from 'src/app/common/api/api.type';
import { handleError } from 'src/app/common/services/error-handler/error-handler.service';
import { catchError, map, tap } from 'rxjs/operators';
import { StorageService } from 'src/app/common/services/storage/storage.service';

@Injectable({
  providedIn: 'root',
})
export class LibraryRevampService {
  private cacheData$: BehaviorSubject<Record<string, any>> =
    new BehaviorSubject<Record<string, any>>({});

  constructor(
    private apiService: PPApiService,
    private storageService: StorageService
  ) {}

  isLibraryEnabledUnleash(batchUserSegment: string): Observable<any> {
    const cohortConfig =
      this.storageService.getValue('UserCohortConfig', 'json') || {};
    const cohortName = `${cohortConfig.class ?? ''} ${cohortConfig.exam ?? ''}`
      .trim()
      .toLowerCase();
    const cacheKey = `${batchUserSegment}_${cohortName}`;

    if (!batchUserSegment || !cohortName) {
      return of(true);
    }

    const cachedData = this.cacheData$.getValue()[cacheKey];
    if (cachedData) {
      return of(cachedData);
    }

    const options: PPApiOptions = {
      apiPath: `v3/uxncc-be/user/widget/showLibrary?batchUserSegment=${batchUserSegment}&cohort=${cohortName}`,
    };

    return this.apiService.get<any>(options).pipe(
      map((res) => res.data),
      tap((data) => {
        const updatedCache = {
          ...this.cacheData$.getValue(),
          [cacheKey]: data,
        };
        this.cacheData$.next(updatedCache);
      }),
      catchError(handleError)
    );
  }
}
