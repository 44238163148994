<section class="flex flex-col">
  <div class="top-section flex flex-col">
    <div (click)="redirectToHome()" class="vertical-align my-2">
      <!-- <ng-lottie
        height="120px"
        width="120px"
        *ngIf="isTeachersDay"
        [options]="teacherLogoOptions"
      ></ng-lottie> -->

      <img *ngIf="isTeachersDay" [src]="holiImage" class="rounded-full" />
      <ng-container *ngIf="!isTeachersDay">
        <img [src]="pwLogo" class="logo-img mr-3" />
        <h1 class="bold body-1 cursor-pointer">Physics Wallah</h1>
      </ng-container>
    </div>

    <div
      *ngIf="cohortSidebarConfig?.isUpdateAcademicInfoEnabled"
      class="vertical-align mt-4 mb-2"
      id="acad-info"
    >
      <!-- [ngClass]="{ 'mt-0': isTeachersDay, 'mt-0': !isTeachersDay }" -->
      <span
        (click)="openCohortModal()"
        class="update-btn body-2 semibold uppercase cursor-pointer"
        mat-button
      >
        Update Academic Info
      </span>
    </div>
    <mat-divider></mat-divider>
  </div>

  <!-- ! Scroll Section -->
  <div
    [ngClass]="{
      'content-div-teachers-day': isTeachersDay,
      'content-div-height-new':
        !cohortSidebarConfig?.isUpdateAcademicInfoEnabled
    }"
    class="content-div overflow-y-scroll px-3"
  >
    <mat-list class="border-bottom">
      <ng-container *ngFor="let item of sidePagesDesktop; let i = index">
        <div
          *ngIf="
            item?.url !== '/library-revamp' ||
            (item?.url === '/library-revamp' && showLibrary)
          "
        >
          <mat-list-item
            (click)="handleItemClick(item, true)"
            *ngIf="item.isEnabled || item.external"
            [id]="'side-nav_btn-' + i"
            [ngClass]="{
              'border-bottom': i === 6,
              'active-tab semibold': item.isActive,
              'only-mobile-enabled': item.onlyMobile
            }"
            class="flex items-center cursor-pointer relative hamburger-item"
          >
            <img
              [ngClass]="{
                'store-img': item.title === 'PW Store',
                'admit-card-img': item.title === 'Submit Your Admit Card'
              }"
              [routerLinkActiveOptions]="{ exact: true }"
              [src]="item.logo"
              class="list-img"
              routerLinkActive="active-icon"
            />
            <span
              [ngClass]="{ 'store-text': item.title === 'PW Store' }"
              class="item-text body-2"
            >
              {{ item?.title === 'VP Live' ? vpTitle : item.title }}</span
            >

            <!-- Dot Indicator for Test Series  -->
            <!--          <div-->
            <!--            *ngIf="item.title === 'Test Series' && isBlinking"-->
            <!--            class="dotIndicator"-->
            <!--          >-->
            <!--            <div class="blinking">-->
            <!--              <span class="dot"></span>-->
            <!--            </div>-->
            <!--          </div>-->

            <ng-lottie
              *ngIf="item.isNew"
              [options]="options"
              height="50px"
              margin="7px"
              width="40px"
            ></ng-lottie>
          </mat-list-item>
        </div>
      </ng-container>
    </mat-list>

    <div [ngClass]="{ 'flex-col-reverse': isExpanded }" class="flex flex-col">
      <div
        (click)="expand()"
        *ngIf="showExpandToggle"
        class="flex items-stretch ml-5 cursor-pointer mt-3 mb-1"
      >
        <span
          [id]="isExpanded ? 'see-less' : 'see-more'"
          class="caption-1 medium see-text"
          >See {{ isExpanded ? 'less' : 'More' }}</span
        >
        <mat-icon *ngIf="isExpanded" class="ml-1 see-text arrow-icon"
          >keyboard_arrow_up
        </mat-icon>
        <mat-icon *ngIf="!isExpanded" class="ml-1 see-text arrow-icon"
          >keyboard_arrow_down
        </mat-icon>
      </div>

      <mat-list *ngIf="isExpanded">
        <ng-container *ngFor="let item of collapsablePages; let i = index">
          <mat-list-item
            (click)="handleItemClick(item, true)"
            *ngIf="item.isEnabled"
            [id]="'item-more_btn-' + i"
            [ngClass]="{ 'active-tab': item.isActive }"
            class="flex items-center cursor-pointer hamburger-item"
          >
            <img [src]="item.logo" class="collapsable-img" />
            <span
              [ngClass]="{
                bold: item.isActive
              }"
              class="item-text body-2"
            >
              {{ item.title }}
            </span>
          </mat-list-item>
        </ng-container>
      </mat-list>
    </div>
  </div>
</section>
