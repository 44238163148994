import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Schedule } from '../batch-weekly-schedule.model';
import { GlobalService } from '../../../../common/services/global/global.service';
import { FileService } from '../../../../common/services/file-service/file-service.service';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { BatchDetailModel } from '../../batch-overview/batch-overview.model';
import { PurchaseAlertComponent } from 'src/app/common/components/purchase-alert/purchase-alert.component';

import {
  ATTACHMENT_CLOSE_ICON,
  ATTACHMENT_DOWNLOAD_ICON,
  ATTACHMENT_EXERCISE_ICON,
  ATTACHMENT_FILE_ICON,
} from 'src/app/core/assets.location';
import {
  ContentService,
  SavePdfStatsModal,
} from 'src/app/common/services/content/content.service';
import { ChapterContentService } from 'src/app/common/services/chapter-content/chapter-content.service';
import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { FirebaseAnalyticsService } from 'src/app/common/services/firebase-analytics/firebase-analytics.service';
import { WEEKLY_SCHEDULE } from 'src/app/core/analytics-events';
import {
  TestInstruction,
  TestSeriesService,
} from 'src/app/common/services/tests/test-series.service';
import { ShowErrorService } from 'src/app/common/services/showError/show-error.service';
import { PracticeService } from 'src/app/common/services/practice/practice.service';

@Component({
  selector: 'app-attachment-dialog',
  templateUrl: './attachment-dialog.component.html',
  styleUrls: ['./attachment-dialog.component.scss'],
  providers: [FileService],
})
export class AttachmentDialogComponent implements OnInit {
  scheduleData: Schedule;
  batchData: BatchDetailModel;
  isFree: boolean;
  subjectName: string;
  dialogCloseImg = ATTACHMENT_CLOSE_ICON;
  fileImg = ATTACHMENT_FILE_ICON;
  exerciseImg = ATTACHMENT_EXERCISE_ICON;
  downloadImg = ATTACHMENT_DOWNLOAD_ICON;
  pdfStats: SavePdfStatsModal;
  userInfo: any;
  activePdfIdQuery: { activeSection: any; pdfId: any };
  activeSection: '';
  cardType: string;
  section: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AttachmentDialogComponent>,
    private router: Router,
    private globalService: GlobalService,
    private fileService: FileService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private contentService: ContentService,
    private chapterContentService: ChapterContentService,
    private firebaseService: FirebaseAnalyticsService,
    private testService: TestSeriesService,
    private showErrorService: ShowErrorService,
    private practiceService: PracticeService
  ) {
    this.scheduleData = data.obj;
    this.isFree = data.isFree;
    this.subjectName = data.subjectName;
    this.cardType = data.cardType;
    this.section = data.section;
  }

  ngOnInit(): void {
    this.globalService.getUser().subscribe((res) => {
      if (res) {
        this.userInfo = res;
      }
    });
    this.batchData = this.storageService.getBatchData();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  openPurchaseDialog() {
    const dialogRef = this.dialog.open(PurchaseAlertComponent, {
      data: {
        batchId: this.batchData.slug,
      },
      height: '160px',
      width: '450px',
      panelClass: 'purchase-alert',
    });
  }

  getQueryParams = (batchScheduleId: any, url: string) => {
    let source = 'BATCH_TEST_SERIES';
    url += `?batchId=${this.batchData._id}&batchName=${this.batchData.name}&source=${source}&batchScheduleId=${batchScheduleId}&cameFrom=DPP`;
    return url;
  };

  getPracticeType(item: any) {
    if (
      item &&
      item.content &&
      item?.content[0]?.exerciseId &&
      item?.content[0]?.exerciseId?.smartDPP
    ) {
      return 'smart-dpp';
    }
    return 'dpp';
  }

  async startExercise(item: any) {
    if (!this.batchData.isPurchased) {
      this.dialogRef.close();
      this.openPurchaseDialog();
      return;
    } else {
      if (item && item.content && item?.content[0]?.exerciseId) {
        // Instructins API call to get instructions and its details for this particular dpp
        const instructions = await this.getTestInstructionsV3(
          item?.content[0]?.exerciseId?._id
        );

        if (instructions?.isSubjective) {
          this.dialogRef.close();

          const childUrl = this.getQueryParams(
            this.scheduleData._id,
            `/test/${item?.content[0]?.exerciseId?._id}/subjective-details`
          );

          this.router.navigate(
            [`tests/${item?.content[0]?.exerciseId?._id}/new-test-result`],
            {
              queryParams: {
                childUrl,
              },
            }
          );
        } else {
          this.globalService.setTestBackUrl(this.router.url);
          this.globalService.setDppBackUrl(this.router.url);
          this.globalService.setQBankReturnURL(this.router.url);
          this.practiceService.startPractice({
            practiceData: item,
            fetchInstructions: true,
            practiceType: this.getPracticeType(item),
            source: 'BATCH_QUIZ',
            scheduleId: this.scheduleData?._id || '',
            localstorageKeysToClear: [
              'testDetails',
              'testType',
              'questionTimer',
              'preDraft-meta',
            ],
            saarthi: {
              enabled: true,
              batchId: this.batchData._id || '',
              isPremium:
                this.batchData.template === 'MASTER' &&
                this.batchData.masterBatchId!.length > 0,
            },
            eventsData: {
              source: 'BATCH_SECTION',
            },
            batchId: this.batchData._id || '',
          });
        }
      } else {
        this.globalService.showSnackBar('Something went wrong');
      }
    }
  }

  setBatchIdSubjectId(item?: any) {
    const data = {
      batchId: this.batchData._id,
      batchScheduleId: item?._id || '',
      cameFrom: 'classroom',
    };
    this.storageService.setBatchIdSUbjectIdTest(data);
  }

  async openContent(content: any) {
    this.activePdfIdQuery = {
      activeSection: this.activeSection,
      pdfId: content.attachmentIds[0]._id,
    };

    this.chapterContentService.setActiveSection(this.activePdfIdQuery);

    if (!this.batchData.isPurchased && !this.isFree) {
      this.dialogRef.close();
      this.openPurchaseDialog();
      return;
    } else {
      if (content.attachmentIds[0]) {
        this.globalService.setPdfBackUrl(this.router.url);
        const data = content.attachmentIds[0];
        if (
          data &&
          data.baseUrl &&
          data.baseUrl.length > 0 &&
          data.key.length > 0
        ) {
          const url = data.baseUrl + data.key;
          this.globalService.setPdfUrl(
            JSON.stringify({ src: url, title: content.attachmentIds[0].name })
          );
          await this.router.navigate([]).then((result) => {
            window.open('batches/open-pdf', '_balnk');
          });
          this.dialogRef.close();

          this.logAttachmentEvent('view');
        } else {
          this.globalService.showSnackBar(`No pdf url found...`);
        }
      } else {
        this.globalService.showSnackBar(`No pdf url found...`);
      }
    }
  }

  download(content: any) {
    if (!this.batchData.isPurchased && !this.data.isFree) {
      this.dialogRef.close();
      this.openPurchaseDialog();
      return;
    }
    const data = content.attachmentIds[0];
    if (data && data.baseUrl && data.key) {
      const url = data.baseUrl + data.key;
      this.fileService.downloadPdfDesktop(url, content.topic);
      this.dialogRef.close();
    }
    this.preparePdfStats(data._id);
    this.logAttachmentEvent('download');
  }

  logAttachmentEvent(action: string) {
    // this.firebaseService.logEvents(
    //   WEEKLY_SCHEDULE.SCHEDULES_CLICK,
    //   {
    //     subject_name: this.subjectName || '',
    //     batch_name: this.batchData.name || '',
    //     batch_id: this.batchData._id,
    //     batch_status: this.batchData.isPurchased
    //       ? 'purchased'
    //       : 'not_purchased',
    //     action: 'attachment_click',
    //     card_type: this.cardType,
    //     section: this.section || '',
    //   },
    //   true,
    //   true,
    //   true
    // );
    this.firebaseService.logEvents(
      WEEKLY_SCHEDULE.ATTACHMENT_POPUP_ACTION,
      {
        subject_name: this.subjectName || '',
        batch_name: this.batchData.name || '',
        batch_id: this.batchData._id,
        batch_status: this.batchData.isPurchased
          ? 'purchased'
          : 'not_purchased',
        action,
        card_type: this.cardType || '',
      },
      true,
      true,
      true
    );
  }

  preparePdfStats(pdfId: any) {
    // const savedStats = this.chapterContentService.getPdfStats() || {};
    // if (savedStats && savedStats.typeId === pdfId) {
    //   this.pdfStats = savedStats;
    //   this.pdfStats.downloadCount++;
    //   this.pdfStats.sessions[0].startTimeTimestamp = new Date().getTime();
    //   this.pdfStats.sessions[0].endTimeTimestamp = new Date().getTime();
    // } else {
    this.pdfStats = {
      type: 'PDF',
      typeId: pdfId,
      belongsTo: 'BATCH',
      userId: this.userInfo.id,
      batchId: this.batchData._id,
      programId: this.batchData.programId,
      subjectId: '',
      batchSubjectId: this.batchData.subjects[0]._id,
      chapterId: '',
      topicId: '',
      testCategoryId: '',
      testId: '',
      subTopicId: null,
      isDownloaded: true,
      downloadCount: 1,
      sessions: [
        {
          deviceType: 'DESKTOP',
          medium: 'WEB',
          startTimeTimestamp: new Date().getTime(),
          endTimeTimestamp: new Date().getTime(),
          networkOperator: '',
        },
      ],
    };
    // }

    this.sendStats();
  }

  async getTestInstructionsV3(exerciseId: string) {
    let instructions = <TestInstruction>{};
    try {
      const res = await lastValueFrom(
        this.testService.getTestInstructionsV3(exerciseId)
      );
      if (res) instructions = <TestInstruction>res;
    } catch (e) {
      //  this.showErrorService.showError(e);
    }

    return instructions;
  }

  async sendStats() {
    try {
      const res: any = lastValueFrom(
        this.contentService.savePdf(this.pdfStats)
      );
      if (res) {
      }
    } catch (e) {
      // await this.showErrorService.showError(e);
    } finally {
      // this.chapterContentService.setPdfStats(this.pdfStats);
    }
  }
}
